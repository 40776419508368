@import 'variables';
@import 'mixins';

/* Shared between dashboard date range picker and shared component date range picker */
.date-range-picker {
  margin-bottom: 10px;

  label {
    color: theme-var($--tl-theme-accent30);
    font: theme-var($--tl-theme-font-h6);
    text-transform: uppercase;
    vertical-align: top;
  }

  .date-range-txt {
    display: inline-block;
    min-width: 75px;
  }

  .date-range-btn {
    padding: $field-padding;
    border: 0;
    background: transparent;
    padding: 0 16px;
    height: $search-medium-height;
    width: 100%;
    text-align: left;
    position: relative;

    &:after {
      display: inline-block;
      vertical-align: .255em;
      content: "";
      border-top: .3em solid;
      border-right: .3em solid transparent;
      border-bottom: 0;
      border-left: .3em solid transparent;
      color: var(--tl-theme-accent30);
      position: absolute;
      top: calc(($search-medium-height/2) - 2px);
      right: 8px;
    }

    &.date-range--empty {
      border-color: theme-var($--tl-theme-accent30);
      color: theme-var($--tl-theme-accent30);

      &:after {
        color: theme-var($--tl-theme-accent30);
      }
    }
  }

  .date-range-btn-group {
    border-radius: $field-border-radius;
    border: $field-border;
    max-width: 360px;
    min-width: 320px;
    background-color: theme-var($--tl-theme-surface);
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    &:hover, &:focus, &:active {
      border-color: theme-var($--tl-theme-primary);

      .date-range-btn:after {
        color: var(--tl-theme-primary);
      }
    }

    &.clearable {
      .date-range-btn:not(.date-range--empty) {
        /* If the date range is clearable and has value, then give space for the clear button */
        width: calc(100% - $search-medium-height);
      }
    }
  }

  .date-range-btn-clear {
    background: theme-var($--tl-theme-accent30);
    border: 0;
    color: theme-var($--tl-theme-dark-text);
    cursor: pointer;
    font-size: 1rem;
    line-height: inherit;
    padding: 0;
    height: $search-medium-height;
    width: $search-medium-height;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: theme-var($--tl-theme-primary);
    }
  }

  .date-range--empty {
    color: theme-var($--tl-theme-accent30);
  }
}

.widget-content-component,
.page-header {
  .date-range-picker {
    margin-bottom: 0;
  }
}