@import './variables';
@import './mixins';

.dropdown {
  &[disabled] {
    color: theme-var($--tl-theme-accent20);
    cursor: default;
  }

  .dropdown-toggle {
    @include dropdownToggle();

    > fa-icon {
      display: none;
      font-size: .625em;
    }
  }
}

.dropdown-menu {
  @include dropdownmenu();

  > header {
    font: var(--tl-theme-font-h4);
    border-bottom: 1px solid var(--tl-theme-accent10);
    padding: 0 0 8px;
    margin-top: -4px;
  }

  &.dropdown-menu-end {
    left: auto;
    right: 0;
  }

  .dropdown-item {
    @include dropdownmenuitem();
  }
}

.table > :not(caption) > * > * {
  background-color: inherit;
  padding: 0;
}

html[data-theme="zoomlight"] {
  .dropdown {
    .dropdown-toggle {
      > fa-icon {
        display: inline-block;
      }

      &:after {
        display: none;
      }

      &:hover {
        color: theme-var($--tl-theme-primary);
      }
    }

    &.show {
      .dropdown-toggle {
        color: theme-var($--tl-theme-primary);
        font-weight: 400;
        text-shadow: 0 0 1px theme-var($--tl-theme-primary);
      }
    }
  }

  .dropdown-menu {
    @include theme-zoom-dropdownmenu();

    .dropdown-item {
      @include theme-zoom-dropdownmenuitem();
    }
  }

  .header-nav__menu-item__dropdown-menu {
    .dropdown-menu {
      .dropdown-item {
        .active {
          padding-left: 30px;
        }
      }
    }
  }
}

// [PORT-12873]
// override default opacity for tooltips.
// all of our tooltips have opacity 1.
.tooltip.show {
  opacity: 1;
}