@font-face {
  font-family: 'OpenSans';
  font-weight: normal;
  src: url('../assets/fonts/OpenSansRegular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'OpenSans';
  font-weight: bold;
  src: url('../assets/fonts/OpenSansBold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'OpenSans';
  font-weight: 600;
  src: url('../assets/fonts/OpenSansSemiBold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: "Almaden Sans";
  font-weight: 400;
  src: url('../assets/fonts/AlmadenSans-Regular-WebXL.woff2') format('woff2'), url("../assets/fonts/AlmadenSans-Regular-WebXL.woff") format("woff");
  font-style: normal;
}

@font-face {
  font-family: "Almaden Sans";
  font-weight: 600;
  src: url('../assets/fonts/AlmadenSans-SemiBold-WebXL.woff2') format('woff2'), url("../assets/fonts/AlmadenSans-SemiBold-WebXL.woff") format("woff");
  font-style: normal;
}

@font-face {
  font-family: "Almaden Sans";
  font-weight: 700;
  src: url('../assets/fonts/AlmadenSans-Bold-WebXL.woff2') format('woff2'), url("../assets/fonts/AlmadenSans-Bold-WebXL.woff") format("woff");
  font-style: normal;
}
