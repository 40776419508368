@import 'variables';
@import './themes/theme-variables';
@import './themes/theme-mixins';

/* a wrapper element that tracks the mouse vertically */
.vjs-thumbnail-holder {
  left: -1000px;
  display: none;
  position: absolute;
}

/* the thumbnail image itself */
.vjs-thumbnail {
  bottom: 1.3em;
  left: 0;
  opacity: 0;
  position: absolute;
  -moz-transition: opacity .2s ease;
  -mz-transition: opacity .2s ease;
  -webkit-transition: opacity .2s ease;
  transition: opacity .2s ease;
}

/* fade in the thumbnail when hovering over the progress bar */
/* .fake-active is needed for Android only. It's removed on touchend/touchecancel */
.vjs-progress-control:hover .vjs-thumbnail,
.vjs-progress-control.fake-active .vjs-thumbnail,
.vjs-progress-control:active .vjs-thumbnail {
  opacity: 1;
}

/* ... but hide the thumbnail when hovering directly over it */
.vjs-progress-control:hover .vjs-thumbnail:hover,
.vjs-progress-control:active .vjs-thumbnail:active {
  opacity: 0;
}

.video-js {
  &.vjs-settings-menu {
    display: block;
  }
}

.vjs-menu-button-popup .vjs-menu .vjs-menu-content {
  max-height: 20em;
}

.video-js.vjs-fullscreen .vjs-control-bar {
  bottom: 0;
}

.vjs-playback-rate .vjs-playback-rate-value {
  font-size: theme-var($--tl-theme-font-body3);
  line-height: 1.875rem;
}

.video-js .vjs-time-control {
  line-height: 2rem;
}

.vjs-icon-cog:before {
  font-size: 1rem;
}

.video-js .vjs-jump-to-start-btn .vjs-icon-placeholder,
.video-js .vjs-jump-to-end-btn .vjs-icon-placeholder,
.video-js .vjs-loop-btn .vjs-icon-placeholder {
  font-family: 'Material Icons';
}

.video-js .vjs-jump-to-start-btn .vjs-icon-placeholder:before {
  content: "\e045";
}

.video-js .vjs-jump-to-end-btn .vjs-icon-placeholder:before {
  content: "\e044";
}

.video-js .vjs-loop-btn .vjs-icon-placeholder:before {
  content: "\e040";
}

.video-js .vjs-loop-btn.vjs-loop-btn--active .vjs-icon-placeholder:before {
  content: "\e9d6";
}

.vjs-loop-control {
  width: 100%;
  height: 30px;
  position: absolute;
  top: 0;

  .vjs-loop-range-selector {
    width: 100%;
  }

  .range-slider {
    height: 20px;
    text-align: center;
    position: relative;
    top: 10px;
  }

  /********** Range Input Styles **********/
  input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    position: absolute;
    left: 0;
    border: none;
    background: transparent;
    cursor: pointer;
    width: 100%;
  }

  /* Removes default focus */
  input[type="range"]:focus {
    border-color: transparent;
    outline: none;
  }

  /***** Chrome, Safari, Opera and Edge Chromium styles *****/
  /* slider track */
  input[type="range"]::-webkit-slider-runnable-track {
    background-color: transparent;
    border-radius: 0.5rem;
    height: 0.5rem;
  }

  /* slider thumb */
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    margin-top: -10px; /* Centers thumb on the track */
    z-index: 2;
    position: relative;
    /*custom styles*/
    background-color: theme-var($--tl-theme-primary);
    height: 30px;
    width: 5px;
  }

  /******** Firefox styles ********/
  /* slider track */
  input[type="range"]::-moz-range-track {
    background-color: transparent;
    border-radius: 0.5rem;
    height: 0.5rem;
  }

  /* slider thumb */
  input[type="range"]::-moz-range-thumb {
    border: none; /*Removes extra border that FF applies*/
    border-radius: 0; /*Removes default border-radius that FF applies*/
    z-index: 2;
    position: relative;
    /*custom styles*/
    background-color: theme-var($--tl-theme-primary);
    height: 30px;
    width: 5px;
  }

  input[type="range"]:focus::-moz-range-thumb {
    border: 1px solid theme-var($--tl-theme-primary);;
    outline: 3px solid theme-var($--tl-theme-primary);;
    outline-offset: 0.125rem;
  }
}

// RESPONSIVE
// https://videojs.com/guides/layout/#responsive-mode

// for x-small and small player, hide seek buttons
.vjs-layout-x-small,
.vjs-layout-small {
  .vjs-seek-button {
    display: none;
  }
}

// for medium player, hide seek buttons based on breakpoint
.vjs-layout-medium {
  .vjs-seek-button {
    // hide seek buttons below lg breakpoint by default
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }
}


.vjs-layout-medium.vjs-is-main-content {
  // when player has the vjs-is-main-content class we show seek buttons by default.
  .vjs-seek-button {
    display: block;
    // and hide them if viewport is less than md
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}