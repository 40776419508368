@import 'themes/theme-variables';
@import 'themes/theme-mixins';

@keyframes wave {
  0%, 60%, 100% {
    transform: initial;
  }

  30% {
    transform: translateY(-10px);
  }
}

@keyframes wave-sm {
  0%, 60%, 100% {
    transform: initial;
  }

  30% {
    transform: translateY(-3px);
  }
}

@keyframes live {
  0%, 100% {
    opacity: 1;
  }

  50% {
    opacity: .4;
  }
}

//

@keyframes logo-loader--rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes logo-loader--dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes logo-loader--color {
  100%,
  0% {
    stroke: theme-var($--tl-theme-primary);
  }
  40% {
    stroke: theme-lightness($--tl-theme-primary, 20%);
  }
  66% {
    stroke: theme-var($--tl-theme-primary);
  }
  80%,
  90% {
    stroke: theme-lightness($--tl-theme-primary, -20%);
  }
}

@keyframes comment-section--emphasis {
  0%   {
    background: rgba(235, 235, 235, 0);
  }
  25% {
    background: rgba(235, 235, 235, 1);
  }
  50% {
    background: rgba(235, 235, 235, 0);
  }
  75% {
    background: rgba(235, 235, 235, 1);
  }
  100% {
    background: rgba(235, 235, 235, 0);
  }
}

@keyframes rca-teams-content--stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes rca-teams-content--scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes rca-teams-content--fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px theme-var($--tl-theme-primary);
  }
}

@keyframes rca-teams-content--tada {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  10%, 20% {
    -webkit-transform: scale(.9) rotate(-8deg);
    transform: scale(.9) rotate(-8deg);
  }

  30%, 50%, 70% {
    -webkit-transform: scale(1.3) rotate(8deg);
    transform: scale(1.3) rotate(8deg);
  }

  40%, 60% {
    -webkit-transform: scale(1.3) rotate(-8deg);
    transform: scale(1.3) rotate(-8deg);
  }

  80%, 100% {
    -webkit-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
}

@keyframes rca-teams-content--spinner {
  0% { transform: rotate(0deg); }
  50% { transform: rotate(360deg); }
  100% { transform: rotate(360deg); }
}
