@import '../theme-variables';
@import '../theme-core';

$btl-font-family: "Almaden Sans", sans-serif;

// based on browser font size of 16px
$btl-font-base: normal, 400, 0.875rem, 1.5, $btl-font-family;  // 14px
$tl-font-small-bold: normal, 700, .75rem, 1.2, $btl-font-family; // 12px bold
$btl-font-large: normal, 400, 1.75rem, 1.5, $btl-font-family;
$btl-font-h1: normal, 600, 1.25rem, 1.5, $btl-font-family;   // 20px
$btl-font-h2: normal, 500, 1.25rem, 1.5, $btl-font-family;   // 20px
$btl-font-h3: normal, 600, 1rem, 1.5, $btl-font-family;      // 16px
$btl-font-h4: normal, 500, 0.875rem, 1.5, $btl-font-family;  // 14px
$btl-font-h5: normal, 500, 0.875rem, 1.5, $btl-font-family;  // 14px
$btl-font-h6: normal, 500, 0.75rem, 1.5, $btl-font-family;   // 12px
$btl-font-label: normal, 500, 0.875rem, 1.5, $btl-font-family; // 14px
$btl-font-body2: normal, 400, 0.75rem, 1.5, $btl-font-family;  // 12px
$btl-font-caption: normal, 500, 0.75rem, 1.5, $btl-font-family; // 12px
$btl-font-body3: normal, 400, 0.75rem, 1.5, $btl-font-family;   // 12px
$btl-font-table: normal, 400, .875rem, 1.2, $btl-font-family; // 14px

$btl-gray-05: 240, 45%, 98%;  // #F7F7FC
$btl-gray-10: 200, 23%, 97%; // #F7F9FA
$btl-gray-20: 204, 22%, 95%; // #F1F4F6
$btl-gray-25: 240, 19%, 95%; // #EFEFF4
$btl-gray-30: 213, 16%, 89%; // #DFE3E8
$btl-gray-40: 217, 12%, 78%; // #C1C6CE
$btl-gray-50: 212, 9%, 63%;  // #98A0A9
$btl-gray-53: 240, 8%, 49%;  // #747487
$btl-gray-60: 213, 8%, 47%;  // #6E7680
$btl-gray-70: 212, 10%, 30%; // #444B53
$btl-gray-80: 213, 13%, 17%; // #252A30
$btl-gray-90: 210, 14%, 9%;  // #131619

$btl-blue-10: 212, 100%, 97%; // #F2F8FF
$btl-blue-20: 213, 84%, 90%;  // #D0E3FB
$btl-blue-60: 213, 89%, 49%;  // #0E72ED
$btl-blue-63: 220, 100%, 52%; // #0B5CFF
$btl-blue-66: 216, 66%, 15%;  // #0D213F
$btl-blue-70: 213, 91%, 37%;  // #0956B5

$btl-gold-60: 33, 100%, 35%; // #B36200
$btl-gold-40: 33, 55%, 60%; // #D19F61


$btl-green-31: 140, 68%, 75%; // #95EBB2
$btl-green-60: 264, 60%, 42%; // #268543

$btl-red-60: 349, 82%, 50%;   // #E8173D;

$btl-yellow-30: 49, 100%, 61%; // #ffd938

$theme-zoomlight: (
  "color": (
    "background": $btl-gray-20, /* background color of the viewport */
    "surface": $white-hsl,     /* background color of the content container */
    "text": $btl-gray-90,
    "text-light": $btl-gray-50,
    "dark-surface": $btl-gray-90,
    "dark-text": $white-hsl,
    "primary": $btl-blue-60,
    "secondary": $btl-gold-60,
    "tertiary": $btl-gray-60,
    "quaternary": $btl-green-60,
    "success": $btl-green-60,
    "danger": $btl-red-60,
    "warn": $btl-gold-60,
    "warn2": $btl-gold-40,
    "info": $btl-blue-70,
    "link": $btl-blue-70,
    "accent": $btl-gray-20, /* Hover color */
    "accent05": $btl-gray-10,
    "accent10": $btl-gray-30,
    "accent20": $btl-gray-40,
    "accent30": $btl-gray-50, /* Field / button border color */
    "accent33": $btl-gray-53, /* Switch Color */
    "accent40": $btl-gray-60,
    "accent50": $btl-gray-60,
    "accent60": $btl-gray-60,
    "accent70": $btl-gray-70,
    "accent80": $btl-gray-80,
    "accent90": $btl-gray-90,
    "primary-accent10": $btl-blue-10,
    "primary-accent20": $btl-blue-20,
    "primary-accent63": $btl-blue-63,
    "primary-accent66": $btl-blue-66,
    "secondary-accent10": $btl-green-31,
    "header-background": $btl-gray-05,  /* Background for header in webview */
    "header-background2": $btl-gray-05, /* F7F7FC. Background color for header in iframe */
    "header-text": $btl-gray-90,
    "search-highlight": $btl-yellow-30,
    "table-header": $white-hsl,
    "table-header-text": $btl-gray-60,
    "table-body-text": $btl-gray-80,
    "table-header-border": $btl-gray-30,
    "table-body-border": $btl-gray-25,
    "blockquote": $btl-blue-10,
  ),
  "font": (
    "large": $btl-font-large,
    "h1": $btl-font-h1,
    "h2": $btl-font-h2,
    "h3": $btl-font-h3,
    "h4": $btl-font-h4,
    "h5": $btl-font-h5,
    "h6": $btl-font-h6,
    "body": $btl-font-base,
    "label": $btl-font-label,
    "body2": $btl-font-body2,
    "caption": $btl-font-caption,
    "body3": $btl-font-body3,
    "table": $btl-font-table,
    "table-body": $btl-font-table,
    "small-bold": $tl-font-small-bold,
  )
);

@include theme-config('zoomlight', $theme-zoomlight);
