@use '@angular/material' as mat;
@import "variables";
@import "themes/theme-variables";
@import "themes/theme-mixins";

@include mat.core();

// The below themes were generated using http://mcg.mbitson.com/
// and were based on our official green and blue colors
$tl-palette-green: (
  50 : #e2f6ef,
  100 : #b6e9d6,
  200 : #85dbbb,
  300 : #54cc9f,
  400 : #30c18b,
  500 : #0bb676,
  600 : #0aaf6e,
  700 : #08a663,
  800 : #069e59,
  900 : #038e46,
  A100 : #baffd7,
  A200 : #87ffb9,
  A400 : #54ff9c,
  A700 : #3bff8d,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #000000,
      400 : #000000,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000,
      A200 : #000000,
      A400 : #000000,
      A700 : #000000,
  )
);

$tl-palette-blue: (
  50 : #e6eef7,
  100 : #c1d5ea,
  200 : #98badc,
  300 : #6e9ece,
  400 : #4f89c4,
  500 : #3074b9,
  600 : #2b6cb2,
  700 : #2461aa,
  800 : #1e57a2,
  900 : #134493,
  A100 : #c6daff,
  A200 : #93b8ff,
  A400 : #6097ff,
  A700 : #4786ff,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #000000,
      400 : #ffffff,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000,
      A200 : #000000,
      A400 : #000000,
      A700 : #000000,
  )
);

$tl-custom-typography-config: mat.m2-define-typography-config(
  $headline-1: mat.m2-define-typography-level(mat-typography-for-font("h1")...),
  $headline-2: mat.m2-define-typography-level(mat-typography-for-font("h2")...),
  $headline-3: mat.m2-define-typography-level(mat-typography-for-font("h3")...),
  $headline-4: mat.m2-define-typography-level(mat-typography-for-font("h4")...),
  $headline-5: mat.m2-define-typography-level(mat-typography-for-font("h5")...),
  $headline-6: mat.m2-define-typography-level(mat-typography-for-font("h6")...),
  $body-1: mat.m2-define-typography-level(mat-typography-for-font("body")...),
  $body-2: mat.m2-define-typography-level(mat-typography-for-font("body2")...),
  $caption: mat.m2-define-typography-level(mat-typography-for-font("caption")...),
);

$tl-app-primary: mat.m2-define-palette($tl-palette-green);
$tl-app-accent: mat.m2-define-palette($tl-palette-blue);
$tl-app-theme: mat.m2-define-light-theme((
  color: (
    primary: $tl-app-primary,
    accent: $tl-app-accent
  ),
  density: 0,
  typography: $tl-custom-typography-config,
));

@include mat.core-theme($tl-app-theme);
/* TODO(mdc-migration): Remove all-legacy-component-themes once all legacy components are migrated*/
//@include mat.all-legacy-component-themes($tl-app-theme);
@include mat.all-component-themes($tl-app-theme);

@include mat.checkbox-density(-1);
