@import 'variables';

html {
  --mdc-tab-indicator-active-indicator-height: 4px;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 42px;

  --mat-tab-header-label-text-font: var(--tl-theme-font-h3-family);
  --mat-tab-header-label-text-size: var(--tl-theme-font-h3-size);
  --mat-tab-header-label-text-tracking: 0;
  --mat-tab-header-label-text-line-height: var(--tl-theme-font-h3-line-height);
  --mat-tab-header-label-text-weight: var(--tl-theme-font-h3-weight);

  .mdc-tab {
    padding-left: 12px;
    padding-right: 12px;
  }
}

.mat-mdc-tab-links {
  gap: 12px;

  .mat-mdc-tab-link {
    text-decoration-color: var($--tl-theme-text);
    &.mdc-tab--active {
      text-decoration-color: var($--tl-theme-primary);
    }
  }
}
