@import 'variables';

.mat-button-toggle-group {
  height: 32px;

  .mat-button-toggle-appearance-standard {
    display: flex;
    align-items: center;
    flex-direction: row;
    height: 100%;

    .mat-button-toggle-button {
      display: flex;
    }

    .mat-button-toggle-label-content {
      line-height: 32px;
    }

    &.mat-button-toggle-checked {
      background-color: theme-var($--tl-theme-primary);
      color: theme-var($--tl-theme-dark-text);
      font-weight: bold;
    }
  }
}
