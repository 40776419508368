@import '../../node_modules/bootstrap/scss/_mixins';
@import './variables';

@mixin tl-shadow {
  box-shadow: 0 0 4px 0 rgba(194, 194, 194, 0.48);
}

@mixin tl-shadow-heavy {
  box-shadow: 0 0 10px 0px rgb(0 0 0 / 48%);
}

@mixin tl-form {
  @include tl-shadow;

  background-color: theme-var($--tl-theme-surface);
  padding: 62px 44px;
}

/*
 * We have form-control class that defined the styling for form field.
 * We don't use a single class tl-input or tl-input-textarea.
 * So only define modifications for tl-input instead.
 */
@mixin tl-input {
  border-radius: $field-border-radius;
  height: $default-form-input-height;
}

/*
 * The latest material UI has no border and padding
 * Use this mixin to overwrite the material UI styling
 */
@mixin tl-mat-input-element {
  border: $field-border;
  padding: $field-padding;
}

@mixin tl-button-sizes {
  &.btn-sm {
    font: theme-var($--tl-theme-font-h6);
    line-height: 1.25 !important;
    padding: 0.375rem 1rem !important;
  }

  &.btn-lg {
    font: theme-var($--tl-theme-font-h3);
    padding: 0.75rem 1rem !important;
  }
}

@mixin tl-theme-button($background-color, $text-color, $border-color: null) {
  cursor: pointer;

  @if ($border-color == null) {
    $border-color: $background-color;
  }

  @if (type-of($background-color) == color) {
    background-color: $background-color;
  } @else {
    background-color: theme-var($background-color);
  }

  @if (type-of($border-color) == color) {
    border: 1px solid $border-color;
  } @else {
    border: 1px solid theme-var($border-color);
  }

  color: theme-var($text-color);
  font: theme-var($--tl-theme-font-body);
  font-weight: 700;
  line-height: 1.25rem !important;
  padding: 0.5rem 0.75rem;
  box-shadow: none;
  transition: background-color 0.15s ease-in-out,
              border-color 0.15s ease-in-out;
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  gap: 5px;

  @include tl-button-sizes;

  &:hover {
    @if (type-of($background-color) == color) {
      background-color: lighten($background-color, 5%);
      border-color: lighten($border-color, 5%);
    } @else {
      background-color: theme-lightness($background-color, 10%);
      border-color: theme-lightness($border-color, 10%);
    }
    color: theme-var($text-color);
  }

  &.active,
  &:active,
  &.active:hover,
  &:active:hover {
    @if (type-of($background-color) == color) {
      background-color: lighten($background-color, 5%);
      border-color: lighten($background-color, 5%);
    } @else {
      background-color: theme-lightness($background-color, 10%);
      border-color: theme-lightness($background-color, 10%);
    }
    color: theme-var($text-color);
  }

  &:focus,
  &:active:focus {
    @if (type-of($background-color) == color) {
      box-shadow: 0 0 0 3px rgba($background-color, 0.25);

    } @else {
      box-shadow: 0 0 0 3px theme-alpha($background-color, 25%);
    }
  }

  &.disabled,
  &:disabled,
  &.disabled:hover,
  &:disabled:hover,
  &:disabled:active:hover  {
    opacity: 0.8;

    /* following rules are to cancel hover effects */
    @if (type-of($background-color) == color) {
      background-color: $background-color;
      border-color: $border-color;
      color: theme-var($text-color);

    } @else {
      background-color: theme-var($background-color);
      border-color: theme-var($border-color);
      color: theme-var($text-color);
    }
  }
}



@mixin tl-theme-zoom-button($background-color, $text-color, $disabled-bg-color, $disabled-text-color, $hoverOffset: -20%, $activeOffset: -40%, $border-color: null) {
  @include tl-theme-button($background-color, $text-color, $border-color);
  border-radius: 8px;

  @if ($border-color == null) {
    /* Set the border color to same as background color if it is not defined */
    border-color: theme-var($background-color);
  }

  padding: 6px 16px;
  font-weight: 510;

  &:hover {
    border-color: theme-var($border-color);

    @if (type-of($background-color) == color) {
      background-color: darken($background-color, 5%);
    } @else {
      background-color: theme-lightness($background-color, $hoverOffset);
    }
  }

  &.active,
  &:active,
  &.active:hover,
  &:active:hover {
    @if ($border-color != null) {
      border-color: theme-var($border-color);
    }

    @if (type-of($background-color) == color) {
      background-color: darken($background-color, 10%);
    } @else {
      background-color: theme-lightness($background-color, $activeOffset);
    }
  }

  &:focus,
  &:active:focus {
    @if ($border-color != null) {
      border-color: theme-var($border-color);
    }

    @if (type-of($background-color) == color) {
      background-color: $background-color;

    } @else {
      background-color: theme-var($background-color);
    }

    box-shadow: 0 0 0 2px theme-var($--tl-theme-surface),
                0 0 0 4px theme-var($--tl-theme-primary);
  }

  &.disabled,
  &:disabled,
  &:hover:disabled {
    opacity: 1;

    @if ($border-color != null) {
      border-color: theme-var($border-color);
    }

    /* following rules are to cancel hover effects */
    @if (type-of($disabled-bg-color) == color) {
      background-color: $disabled-bg-color;
    } @else {
      background-color: theme-var($disabled-bg-color);
    }

    @if (type-of($disabled-text-color) == color) {
      color: $disabled-text-color;
    } @else {
      color: theme-var($disabled-text-color);
    }
  }
}

@mixin tl-group-button($background-color, $text-color, $border-color) {
  color: theme-var($text-color);
  background-color: theme-var($background-color);
  border-color: theme-var($border-color);
}

@mixin tl-nav-bar {
  height: $topbar-height;

  .nav-item {
    white-space: nowrap;

    .nav-link {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      color: theme-var($--tl-theme-header-text);

      &.active {
        font-weight: bold;
      }
    }
  }
}

@mixin search-marker($image-base-path) {
  background-image: url('#{$image-base-path}/search-marker.png');
  background-size: 16px 16px;
  content: "";
  display: inline-block;
  height: 16px;
  left: -6px;
  position: absolute;
  top: 5px;
  width: 16px;
}

@mixin placeholder-style() {
  color: theme-var($--tl-theme-text) !important;
  font-style: italic !important;
  opacity: 0.6 !important;
}

@mixin management-action-button {
  min-width: 125px;
  white-space: nowrap;
}

@mixin empty-table-text {
  font-style: italic;
  margin-top: -10px;
  text-align: center;
}

@mixin not-allowed {
  cursor: not-allowed;
  opacity: .6;
}

@mixin container {
  /* shadow configurations */
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  background: theme-var($--tl-theme-surface);
}

@mixin dropdownmenu {
  background-color: theme-var($--tl-theme-surface);
  color: theme-var($--tl-theme-text);
  font: theme-var($--tl-theme-font-body);
  text-align: left;
  list-style: none;
  background-clip: padding-box;

  border-radius: 0;
  border: 0;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5), inset 0 4px 0 0 theme-var($--tl-theme-primary);
  min-width: 125px;
  max-width: 480px;
  max-height: 720px;
  width: auto;
  height: auto;
  overflow: auto;

  padding: 0.25rem 0 0;
}

@mixin dropdownmenu-dark {
  @include dropdownmenu();
  background-color: theme-var($--tl-theme-header-background);
  color: theme-var($--tl-theme-header-text);
}

@mixin dropdownToggle {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  max-width: 480px;
  gap: 8px;
}

@mixin dropdownmenuitem {
  cursor: pointer;
  padding: 5px 10px;
  color: theme-var($--tl-theme-text);
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: 0;
  background-color: transparent;
  text-align: left;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;

  &:hover, &:active {
    background-color: theme-var($--tl-theme-accent);
  }

  &.active {
    background-color: theme-var($--tl-theme-accent);
    color: theme-var($--tl-theme-primary); // prevent text from turning white when user clicks
    font-weight: bold;
  }

  &.disabled {
    color: theme-var($--tl-theme-accent30);
    font-weight: normal !important;
  }

  &.tl-menu-divider {
    box-shadow: inset 0 4px 0 0 theme-var($--tl-theme-primary);
    padding-top: 10px !important;
  }
}

@mixin theme-zoom-dropdownmenu {
  box-shadow: 0 6px 12px 0 rgba(19, 22, 25, 0.1), 0 12px 24px 0 rgba(19, 22, 25, 0.1);
  border: $border-accent10;
  border-radius: 8px;
  padding-top: 0;

  .tl-menu-divider,
  .dropdown-item.tl-menu-divider {
    box-shadow: none;
    border-top: $border-accent10;
    padding-top: 6px !important;
  }
}

@mixin theme-zoom-dropdownmenuitem {
  padding: 6px 30px;

  &.active {
    padding-left: 8px;
    background-color: theme-var($--tl-theme-accent);
    color: theme-var($--tl-theme-text);
    font-weight: 400;

    fa-icon {
      display: inline-block;
    }
  }
}

@mixin theme-zoom-container {
  box-shadow: none;
  border-radius: 12px;
  border: $border-accent10;
}

@mixin tl-btn-comment-action {
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background-color: theme-lightness($--tl-theme-accent20, 10%);
  border: none;
  border-radius: 5px;
  color: theme-var($--tl-theme-text);
  font: theme-var($--tl-theme-font-body3);
  cursor: pointer;
  height: 18px;
  padding: 0px 7px;
  gap: 5px;
}

@mixin statistic-info-box {
  .statistic-info-box {
    align-items: center;
    display: flex;
    font: theme-var($--tl-theme-font-h3);
    justify-content: flex-start;
    text-align: center;

    .statistic-info-box-label {
      font-weight: normal;
      margin-bottom: 0;
      margin-left: 5px;
    }

    .box {
      align-items: center;
      color: theme-var($--tl-theme-dark-text);
      font: theme-var($--tl-theme-font-h2);
      display: flex;
      justify-content: center;
      height: 2.5rem;
      min-width: 2.75rem;
      padding: 0 5px;
      position: relative;
    }
  }
}

@mixin tl-pill {
  background: theme-var($--tl-theme-surface);
  border: $border-accent10;
  border-radius: 99px;
  font: theme-var($--tl-theme-font-body3);
  height: 26px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 7px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  box-shadow: $box-shadow;
  margin: 6px 5px 6px 6px;

  &.active{
    border-color: theme-var($--tl-theme-secondary);
  }

  &:hover {
    background: $filter-highlight-color;
  }
}

@mixin tl-attribute-value-key {
  max-width: 80%;
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
}

@mixin tl-attribute-value-text {
  color: theme-var($--tl-theme-text-light);
  font: theme-var($--tl-theme-font-caption);
  text-transform: uppercase;
  padding-left: 6px;
}