@import 'variables';

.ng-scroll-content{
  padding-right: 10px;
}


perfect-scrollbar > .ps.ps--active-y {
  & >.ps__rail-y {
    width: 7px !important;

    &:hover {
      width: 10px !important;
    }
  }
}

.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y:hover > .ps__thumb-y {
  width: 8px !important;

  &:hover {
    width: 8px !important;
  }

  .ps__thumb-y {
    background-color: theme-var($--tl-theme-dark-surface);
    border-radius: 0;
    width: 3px;
  }
}
