@import 'variables';

$dialogPadding: 24px;

// ----------------------------------------------------------------------------------------------
// SECTION 1: Custom Stylings
// ----------------------------------------------------------------------------------------------
.mat-mdc-dialog-title {

  .btn-icon-close {
    font-size: 2em; /* use relative font size */
    margin: 0;
    margin-right: -5px;
    margin-top: -5px;
    align-self: flex-start;
    color: theme-var($--tl-theme-text);

    &:hover {
      color: theme-var($--tl-theme-accent80);
    }
  }
}

.tl-dialog-header,
.tl-dialog-title {
  text-transform: capitalize;
  margin-bottom: 0;
}

.tl-dialog-secondary-title {
  text-transform: capitalize;
}

// ----------------------------------------------------------------------------------------------
// SECTION 2: OVERRIDES FOR ANGULAR MATERIAL MODAL STYLES
// ----------------------------------------------------------------------------------------------
.mat-mdc-dialog-container .mdc-dialog__container {
  color: inherit;
  overflow: visible !important; // multi add dropdown needs to be visible outside modal container
  letter-spacing: normal;

  .mat-mdc-dialog-title.mdc-dialog__title {
    border-bottom: $border-accent10;
    color: inherit;
    letter-spacing: inherit;
    padding: 20px 0 16px;
    margin: 0 $dialogPadding;
    line-height: $modal-title-line-height;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    &:before {
      display: none;
    }

    &.mat-dialog-title--secondary {
      border-bottom: 0;
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-start;

      &:before {
        height: 0;
      }

      > * {
        width: 100%;
      }
    }

    &.is-loading {
      padding-top: 1rem;
    }

    .tl-dialog-title-wrapper {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .mdc-dialog__surface {
    overflow-y: unset;
  }

  .mdc-dialog__content {
    color: inherit;
    letter-spacing: inherit;
    line-height: 1.5;

    &.tl-filter-options-dialog-v2 {
      max-height: calc(65vh + 76px);
    }
  }

  .mdc-dialog__title + .mdc-dialog__content,
  .mdc-dialog__header + .mdc-dialog__content {
    padding-top: $gutter;
  }

  .mdc-dialog__actions {
    letter-spacing: inherit;
    flex-wrap: nowrap;
    gap: 0.5rem;
    padding: 0.5rem $dialogPadding $dialogPadding;

    button, tl-button-loader {
      flex: 1 1 100%;
    }

    &.tl-mat-dialog-actions--fixed {
      justify-content: center;

      button, tl-button-loader {
        flex: none;
      }
    }

    &.tl-mat-dialog-actions--border-top {
      padding: 16px 0 $dialogPadding;

      border-top: $border-accent10;
      margin-left: $dialogPadding;
      margin-right: $dialogPadding;
    }
  }

  // action panel is nested inside the content container
  .mat-mdc-dialog-content {
    overflow-x: hidden;

    .mdc-dialog__actions {
      padding: 0;
      margin-top: 1.5rem;
    }
  }

  .tl-form {
    box-shadow: none;
    padding: 0;
    width: 100%;
  }

  .mat-stepper-horizontal {
    .mat-horizontal-stepper-header-container {
      padding: 0 24px;
    }

    .mat-horizontal-content-container {
      .mat-mdc-dialog-content {
        padding: 0;
      }

      .mat-mdc-dialog-actions {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
      }
    }
  }
}


.cdk-overlay-container,
.cdk-overlay-pane,
.mat-datepicker-content {
  z-index: 2000 !important;
}

.cdk-overlay-pane.review-details-dialog {
  margin-top: 2vh !important;
}

// forces the mat-dialog-content scrollbar to be visible when the modal is scrollable
// this provides a visual indication to the user that they can scroll to find more content
mat-dialog-content::-webkit-scrollbar { -webkit-appearance: none; }
mat-dialog-content::-webkit-scrollbar:vertical { width: 11px; }
mat-dialog-content::-webkit-scrollbar:horizontal { height: 11px; }
mat-dialog-content::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid theme-var($--tl-theme-surface); /* should match modal background, can't be transparent */
  background-color: theme-alpha($--tl-theme-dark-surface, 50%);
}

mat-dialog-actions.mat-mdc-dialog-actions {
  padding-bottom: 20px;
  gap: 0.5rem;

  button {
    min-width: 140px;
  }
}

.mat-input-element {
  box-sizing: border-box;
}
