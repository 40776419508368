@import 'variables';

.mat-mdc-menu-panel {
  border-top: 4px solid theme-var($--tl-theme-primary);
  border-radius: 0;
  max-width: 320px !important;

  .mat-mdc-menu-content {
    border-radius: 0;

    .mat-mdc-menu-item {
      line-height: 30px;
      min-height: 30px;

      &:hover:not([disabled]) {
        background-color: theme-var($--tl-theme-accent);
      }
    }
  }

  .mat-mdc-menu-content:not(:empty) {
    padding: 0;
  }
}

html[data-theme="zoomlight"] {
  .mat-mdc-menu-panel{
    border-top: 0;

    @include theme-zoom-dropdownmenu();
  }
}
