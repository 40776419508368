@import 'variables';

.drag-border {
  border: none;
  opacity: 0.5;
}

.drag-transit {
  border: none;
}
