@use "sass:map";
@import "bootstrap-pre";
@import 'variables';
@import 'mixins';

.tl-input {
  @include tl-input;
}

.tl-input-textarea {
  @include tl-input;
  min-height: 70px;
  resize: vertical;
}

.tl-btn-primary {
  @include tl-theme-button($--tl-theme-primary, $--tl-theme-dark-text);

  &--secondary {
    /* reverse the background & text colors of primary */
    @include tl-theme-button($--tl-theme-dark-text, $--tl-theme-primary, $--tl-theme-accent20);
    border-color: theme-var($--tl-theme-primary);

    &:hover {
      border-color: theme-var($--tl-theme-primary);
    }

    &:focus,
    &:active:focus {
      border-color: theme-var($--tl-theme-primary);
    }

    &:active,
    &:hover:active {
      border-color: theme-var($--tl-theme-primary);
    }
  }
}

.tl-btn-secondary {
  @include tl-theme-button($--tl-theme-secondary, $--tl-theme-dark-text);
}

.tl-btn-tertiary {
  @include tl-theme-button($--tl-theme-surface, $--tl-theme-text, $--tl-theme-accent20);

  &:hover {
    color: theme-var($--tl-theme-primary);
    border-color: theme-var($--tl-theme-primary);
  }

  &.active,
  &.active:hover,
  &:focus,
  &:active:focus {
    border-color: theme-var($--tl-theme-primary);
    color: theme-var($--tl-theme-primary);
  }

  &:active,
  &:hover:active {
    border-color: theme-var($--tl-theme-primary);
    color: theme-var($--tl-theme-primary);
  }
}

.tl-btn-quaternary {
  /* For thetalake theme, set the 4th level button to be the same style as 2nd level button
   * This one is only used in the load previous and load next in chat viewer
   */
  @include tl-theme-button($--tl-theme-secondary, $--tl-theme-dark-text);
}

.tl-btn-danger {
  @include tl-theme-button($--tl-theme-danger, $--tl-theme-dark-text);
}

.tl-btn-warn {
  @include tl-theme-button($--tl-theme-warn, $--tl-theme-dark-text);
}

.tl-btn-transparent {
  @include tl-theme-button(transparent, $--tl-theme-text, transparent);

  &:focus,
  &:active:focus {
    box-shadow: none;
  }

  &.order {
    /* this class is only added for sort button in table header */
    color: theme-var($--tl-theme-accent20);
    flex-flow: column nowrap;
    padding: 0px;
    border: 0;

    svg {
      display: block;
      font-size: 0.75rem;
      opacity: .4;
      margin-bottom: -5px;
      margin-top: -5px;
    }

    .order--up {
      &.active {
        svg {
          opacity: 1;
        }
      }
    }

    .order--down {
      &.active {
        svg {
          opacity: 1;
        }
      }
    }
  }
}

.tl-btn-group {
  padding: 10px 20px;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: $gutter;
}

.btn-load-more {
  font: theme-var($--tl-theme-font-h6);
  min-width: 240px;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  gap: 5px;

  tl-spinner-icon {
    display: block;
  }
}

.tl-btn-integration {
  @include tl-theme-button($--tl-theme-surface, $--tl-theme-dark-text, --tl-theme-background);
  border-radius: 2px;
  margin-bottom: $gutter;
  padding: 0.75rem 1rem !important;
  width: 100%;

  &:hover,
  &--active {
    border-color: theme-var($--tl-theme-info);
    color: theme-var($--tl-theme-info);
  }

  img {
    max-height: 34px;
    max-width: 200px;
  }

  &.hasbetatag {
    img {
      max-width: 175px;
    }
  }
}

.tl-tab-white {
  @include tl-theme-button(transparent, $--tl-theme-text-light, transparent);

  border-radius: 3px;

  &:hover,
  &.active,
  &:hover:active {
    color: theme-var($--tl-theme-text);
    background-color: theme-var($--tl-theme-surface);
    border-color: theme-var($--tl-theme-accent20);
    box-shadow: 0px 1px 2px rgba(0,0,0,0.25);
  }

  .mat-icon {
    font-size: 1rem;

    &.tl-history {
      transform: scaleX(-1);
    }
  }
}

.tl-form {
  @include tl-form;
}

h3 span + tl-info-tooltip {
    margin-left: 5px;
}

$select-arrow-border: 2px solid #999;
.ng-select {
  .ng-select-container {
    border: $field-border;
    font: theme-var($--tl-theme-font-body);
  }

  .ng-arrow-wrapper .ng-arrow {
    height: 8px !important;
    width: 8px !important;
    transform: rotate(45deg);
    transform-origin: center center;
    top: -2px;
    border-top: 0;
    border-right: $select-arrow-border;
    border-bottom: $select-arrow-border;
    border-left: 0;

  }

  &.ng-select-opened {
    > .ng-select-container .ng-arrow {
      height: 8px !important;
      width: 8px !important;
      top: 2px;
      border-top: $select-arrow-border;
      border-right: 0;
      border-bottom: 0;
      border-left: $select-arrow-border;
    }
  }
}

.tl-has-danger {
  color: theme-var($--tl-theme-danger);

  .tl-input,
  .tl-input:focus,
  .tl-input-textarea {
    border: 1px solid theme-var($--tl-theme-danger);
  }

  ng-select > .ng-select-container,
  ng-select > .ng-select-container:focus {
    border: 1px solid theme-var($--tl-theme-danger);
  }
}

.mdc-tooltip .mdc-tooltip__surface {
  font-weight: normal;
}

select.form-control {
  padding-top: .35rem;
  padding-bottom: .35rem;
}

input, select, textarea {
  color: theme-var($--tl-theme-text) !important;
  border: $field-border;

  &:focus {
    border-color: theme-var($--tl-theme-primary);
    outline: theme-var($--tl-theme-primary);
  }
}

.form-group {
  margin-bottom: 1rem;

  > label {
    .form-group--label--required {
      font: theme-var($--tl-theme-font-body2);
      color: theme-var($--tl-theme-text-light);
    }
  }

  &.form-group--inline {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    gap: $gutter;

    label {
      font-weight: bold;
      font-size: theme-var($--tl-theme-font-label);
      margin-bottom: 0;
    }
  }
}

.form-control {
  border: $field-border;
  background-color: theme-var($--tl-theme-surface);
  font: theme-var($--tl-theme-font-body);

  &:disabled {
    background-color: theme-var($--tl-theme-accent10);
  }

  &:hover,
  &:focus {
    box-shadow: none;
    border-color: theme-var($--tl-theme-primary);
  }
}

.form-label,
.form-check-label {
  font: theme-var($--tl-theme-font-body);
  margin-bottom: 0;
}

.form-check {
  height: auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  min-height: auto;
  padding-left: 0;
  margin-bottom: .25rem;

  input[type="checkbox"],
  .form-check-input {
    border: 1px solid theme-var($--tl-theme-accent40);
    height: 16px;
    width: 16px;
    margin: 0;
    float: none;

    &:checked {
      border-color: theme-var($--tl-theme-secondary);
      background-color: theme-var($--tl-theme-secondary);
    }
  }
}

.tl-inline-input {
  padding-left: 0;
  padding-top: 0;
  padding-right: 0;
  border: none;
  border-bottom: $field-border;
  border-radius: 0;

  &:focus {
    border-color: theme-var($--tl-theme-primary);
  }

  &--secondary {
    font-size: 80%;
    font-weight: normal;
  }
}

textarea.tl-inline-input {
  line-height: 1.5;
  padding-top: 3px;
}

.tl-has-danger {
  .tl-input.tl-inline-input {
    border: none;
    border-bottom: solid 1px theme-var($--tl-theme-danger);
  }
}

.tl-form-banner {
  background-color: theme-var($--tl-theme-primary);
  color: theme-var($--tl-theme-dark-text);
  font: theme-var($--tl-theme-font-label);
  margin-bottom: 10px;
  width: 373px;
  padding: 10px 15px;
  text-align: center;

  p {
    margin-bottom: 0;
  }
}

// Bootstrap 4.6 Overwrites

.input-group {
  > .form-control {
    margin: 0;
  }
}

.input-group-append {
  background-color: theme-var($--tl-theme-accent10);
  border-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-clip: border-box;
  border: $field-border;
  border-left: 0;
  padding: 0.35rem 0.75rem;
}

.btn {
  padding: .5rem .75rem;
  line-height: 1.25;
  border-radius: 4px;
}

.form-slider {
  padding-top: 0;
  padding-bottom: 0;

  .mat-slider {
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0;
  }
}

.form-help-btn {
  background: transparent;
  border: none;
  color: theme-var($--tl-theme-accent70);
  cursor: pointer;
  line-height: 1;
  padding: 0;
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: center;

  &:hover {
    color: theme-var($--tl-theme-accent30);
  }
}

.form-help-tooltip {
  background: theme-var($--tl-theme-background);
  display: block;
  font: theme-var($--tl-theme-font-body3);
  overflow: hidden;

  p {
    margin: 0;
  }

  &.is-open {
    padding: 5px 7px;
    margin-bottom: 5px;
  }
}

.ng-dropdown-panel {
  .ng-dropdown-panel-items {
    .ng-option .ng-option-label {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: middle;
      width: 100%;
    }
  }

  .ng-optgroup {
    color: theme-var($--tl-theme-accent70);
    font-weight: 600;
    text-transform: uppercase;
    background-color: theme-var($--tl-theme-accent10);
  }
}

.mdc-form-field {
  .mdc-checkbox + label {
    margin-bottom: 0;
    height: auto;
    width: auto;
  }
  .mdc-radio + label {
    margin-bottom: 0;
  }
}

.mat-mdc-form-field-error {
  font: theme-var($--tl-theme-font-body2);
  color: theme-var($--tl-theme-danger);
}

.mat-mdc-radio-button,
.mat-mdc-checkbox {
  margin-left: -$gutter;

  label {
    color: theme-var($--tl-theme-text);
    font: theme-var($--tl-theme-font-body);
  }
}

.mat-mdc-checkbox .mdc-form-field .mdc-checkbox {
  .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
  .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
  .mdc-checkbox__native-control[data-indeterminate=true]:enabled ~ .mdc-checkbox__background {
    border-color: theme-var($--tl-theme-primary);
    background-color: theme-var($--tl-theme-primary);
  }
}

.mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: theme-var($--tl-theme-primary);
}

.space-participants-list {
  .mat-mdc-checkbox .mat-mdc-checkbox-touch-target {
    width: 36px;
    height: 36px;
  }
}

.mat-mdc-radio-button {
  .mdc-radio {
    /* Radio is enabled but not checked yet */
    .mdc-radio__native-control:enabled {
      &:checked {
        + .mdc-radio__background {
          .mdc-radio__outer-circle, .mdc-radio__inner-circle {
            border-color: theme-var($--tl-theme-primary) !important;
          }
        }
      }

      &:not(:checked) {
        + .mdc-radio__background .mdc-radio__outer-circle {
          border-color: theme-var($--tl-theme-accent40) !important;
        }
      }
    }
  }

  &.mat-mdc-radio-checked {
    .mat-ripple-element {
      background-color: theme-var($--tl-theme-primary, transparent) !important;
    }
  }
}

.form-communication-direction {
  .form-group.form-group--inline {
    > label {
      min-width: 120px;
    }

    .mat-mdc-radio-group {
      display: flex;
      gap: map.get($spacers, 4)
    }
  }

  .mat-mdc-radio-button .mdc-radio {
    padding: 5px;

    .mat-mdc-radio-touch-target, .mdc-radio__native-control {
      height: 30px;
      width: 30px;
    }
  }
}

.mat-mdc-slide-toggle {
  .mdc-form-field {
    .mdc-switch {
      &:enabled {
        &,
        &:active,
        &:focus:not(:active),
        &:hover:not(:focus):not(:active) {
          .mdc-switch__track {
            &:before {
              background: theme-var($--tl-theme-accent20);
            }

            &:after {
              background: theme-alpha($--tl-theme-primary, 50%);
            }
          }
        }
      }

      &.mdc-switch--selected {
        &:enabled {
          .mdc-switch__handle:after {
            background: theme-lightness($--tl-theme-primary, 10%);
          }

          &:active,
          &:focus:not(:active),
          &:hover:not(:focus):not(:active) {
            .mdc-switch__handle {
              &:after {
                background: theme-var($--tl-theme-primary);
              }
            }
          }

          &:active,
          &:focus,
          &:hover:not(:focus) {
            .mdc-switch__ripple {
              &:before, &:after {
                background: theme-var($--tl-theme-primary);
              }
            }
          }
        }
      }

      &.mdc-switch--unselected {
        &:enabled {
          .mdc-switch__handle {
            &:after {
              background: theme-var($--tl-theme-accent60);
            }
          }
        }
      }
      + .mdc-label {
        color: theme-var($--tl-theme-accent40);
      }
    }
  }
}

// mat-select panel override
.cdk-overlay-pane-expandable {
  min-width: min-content;
}

// overrides for edit custom policy
.cdk-overlay-pane:not(.mat-mdc-select-panel-above) .mdc-menu-surface.mat-mdc-select-panel.mat-mdc-select-panel-policy-riskType {
  margin-left: -220px;
  width: 260px;
}

.mat-mdc-form-field {
  background-color: theme-var($--tl-theme-surface);
  border: $field-border;
  width: 100%;

  &.mat-focused.mat-primary {
    .mat-mdc-select-arrow {
      color: theme-var($--tl-theme-primary);
    }
  }

  .mat-mdc-form-field-focus-overlay,
  &.mat-focused .mat-mdc-form-field-focus-overlay,
  .mat-mdc-text-field-wrapper:hover .mat-mdc-form-field-focus-overlay {
    display: none; /* Don't show the field background focus or hover color */
  }

  .mat-mdc-form-field-icon-suffix {
    display: flex;
  }

  &.risk-score-container {
    border: 0;
    background: transparent;

    &.mat-mdc-form-field-type-mat-select:not(.mat-form-field-disabled) {
      .mat-mdc-text-field-wrapper {
        background: transparent;
        padding: 0;

        &.mdc-text-field--no-label .mat-mdc-form-field-infix {
          padding: 0;
          min-height: auto;
          text-align: right;
          width: 160px;

          .mat-mdc-select-trigger {
            justify-content: flex-end;
          }

          .risk-score-title {
            display: block;
            margin-right: 3px;
          }

          .mat-mdc-select-value {
            width: auto;
            margin-right: 8px;
          }
        }
      }
    }

    .mat-mdc-form-field-subscript-wrapper, .mdc-line-ripple {
      display: none;
    }
  }
}

.mat-mdc-icon-button.mat-mdc-button-base {
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  .mat-mdc-button-touch-target {
    height: 100% !important; /* Use !important to override mat styling */
    width: 100% !important;
  }
}

tl-context-menu .mat-mdc-icon-button.mat-mdc-button-base {
  padding: 0;
  height: 28px;
  width: 28px;
  overflow: hidden;
}

// These styles are overrides for the risk score selector in the compliance review media viewer
tl-compliance-review tl-datum .mat-mdc-form-field.risk-score-container {
  // we want the pointer cursor, even when the mouse is over the "risk score" text in the field
  cursor: pointer;
  margin-right: -14px; // align the right edge

  // this hides a border on the bottom of the input
  .mdc-line-ripple:before {
    display: none;
  }

  // hide the subscribt wrapper space. This is empty for us anyways, but takes up space.
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }

  .risk-score-title {
    font-size: theme-var($--tl-theme-font-body2);

    @media (min-width: 1400px) {
      font-size: theme-var($--tl-theme-font-body);
    }
  }

  // by default the select has a light gray background, but we don't want that here
  .mat-mdc-text-field-wrapper {
    background-color: theme-var($--tl-theme-surface);
    margin-right: -16px; /* push it to the right */

    .mat-mdc-form-field-infix {
      text-align: right;

      .mat-mdc-select-trigger {
        justify-content: flex-end;
      }
    }
  }

  // this positions the arrow next to the score indicator
  .mat-mdc-select-value {
    width: auto;
    margin-right: 5px;
  }
}

// datepicker
.mat-calendar-table {
  .mat-calendar-table-header th {
    color: theme-var($--tl-theme-text-light);
    font: theme-var($--tl-theme-font-body3);
    border: 0;
    background: transparent;
    padding: 0 0 8px 0;
  }

  .mat-calendar-body-label,
  .mat-calendar-body-cell-container {
    border: 0;
  }

  .mat-calendar-body-selected {
    background-color: theme-var($--tl-theme-primary);
  }
}

.loading-info {
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  text-align: center;
}

.actions {
  width: auto;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 0.5rem;

  button {
    min-width: 120px;
  }

  button[disabled],
  .not-allowed {
    @include not-allowed;
  }
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate {
  &.mat-pseudo-checkbox-minimal:after {
    color: theme-var($--tl-theme-surface) !important;
  }
}

html[data-theme="thetalakelight"] {

  .mat-mdc-checkbox .mdc-form-field .mdc-checkbox {
    .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
    .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
    .mdc-checkbox__native-control[data-indeterminate=true]:enabled ~ .mdc-checkbox__background {
      border-color: theme-var($--tl-theme-secondary);
      background-color: theme-var($--tl-theme-secondary);
    }
  }

  .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
    background: theme-var($--tl-theme-secondary);
  }

  .mat-mdc-radio-button {
    .mdc-radio {
      /* Radio is enabled but not checked yet */
      .mdc-radio__native-control:enabled {
        &:checked {
          + .mdc-radio__background {
            .mdc-radio__outer-circle, .mdc-radio__inner-circle {
              border-color: theme-var($--tl-theme-secondary) !important;
            }
          }
        }
      }
    }

    &.mat-mdc-radio-checked {
      .mat-ripple-element {
        background-color: theme-var($--tl-theme-secondary, transparent) !important;
      }
    }
  }
}

html[data-theme="zoomlight"] {

  .form-check {
    input[type="checkbox"],
    .form-check-input {
      border: 1px solid theme-var($--tl-theme-accent40);

      &:checked {
        border-color: theme-var($--tl-theme-primary);
        background-color: theme-var($--tl-theme-primary);
      }
    }
  }

  .tl-btn-primary {
    @include tl-theme-zoom-button($--tl-theme-primary, $--tl-theme-dark-text, $--tl-theme-accent, $--tl-theme-accent40);
  }

  .tl-btn-secondary {
    @include tl-theme-zoom-button($--tl-theme-accent, $--tl-theme-text, $--tl-theme-accent, $--tl-theme-accent40);

    &:hover {
      background-color: theme-var($--tl-theme-accent10);
    }

    &.active,
    &:active,
    &.active:hover,
    &:active:hover {
      background-color: theme-var($--tl-theme-accent20);
    }
  }

  .tl-btn-tertiary {
    @include tl-theme-zoom-button($--tl-theme-surface, $--tl-theme-text, $--tl-theme-surface, $--tl-theme-accent40, -10%, -20%, $--tl-theme-accent20);

    &:hover {
      background-color: theme-var($--tl-theme-accent05);
    }

    &.active,
    &:active,
    &.active:hover,
    &:active:hover {
      background-color: theme-var($--tl-theme-accent);
    }

    &:disabled:hover,
    &.active:disabled,
    &:disabled:active,
    &.active:disabled:hover,
    &:disabled:active:hover {
      background-color: theme-var($--tl-theme-surface);
      color: theme-var($--tl-theme-accent40);
    }
  }

  .tl-btn-quaternary {
    @include tl-theme-zoom-button(transparent, $--tl-theme-link, transparent, $--tl-theme-accent40);

    &:hover {
      background-color: theme-var($--tl-theme-primary-accent10);
    }

    &.active,
    &:active,
    &.active:hover,
    &:active:hover {
      background-color: theme-var($--tl-theme-primary-accent20);
    }
  }

  .tl-btn-danger {
    @include tl-theme-zoom-button($--tl-theme-danger, $--tl-theme-dark-text, $--tl-theme-accent, $--tl-theme-accent40, -10%, -20%);
  }

  .tl-btn-transparent.order {
    color: theme-var($--tl-theme-accent50);
    height: .5rem;
    display: flex;
    justify-content: space-between;

    .active {
      color: theme-var($--tl-theme-accent80);
    }

    svg {
      font-size: .5rem;
      opacity: 1;
    }
  }

  .btn-group-toggle {
    > label {
      display: flex;
      flex-flow: row;
    }
  }

  .mat-mdc-slide-toggle {
    .mdc-form-field {
      .mdc-switch {
        width: 36px;

        .mdc-switch__track {
          height: 20px;
          border-radius: 10px;
        }

        .mdc-switch__handle-track {
          height: 16px;
          width: 16px;
          top: 2px;
          left: 2px;
        }

        .mdc-switch__handle {
          height: 100%;
          width: 100%;
        }

        &,
        &.mdc-switch--unselected,
        &.mdc-switch--selected {
          &:enabled, &:disabled {
            .mdc-switch__handle {
              &:before, &:after {
                background: theme-var($--tl-theme-surface);
              }
            }

            &:focus:not(:active),
            &:hover:not(:focus):not(:active) {
              .mdc-switch__handle {
                &:before, &:after {
                  background: theme-var($--tl-theme-surface);
                }
              }
            }
          }
        }

        .mdc-switch__icons {
          display: none;
        }

        &:enabled, &:disabled {
          &,
          &:active,
          &:focus:not(:active),
          &:hover:not(:focus):not(:active) {
            .mdc-switch__track {
              &:before {
                background: theme-var($--tl-theme-accent30);
              }

              &:after {
                background: theme-var($--tl-theme-primary);
              }
            }
          }
        }

        &:disabled {
          .mdc-switch__track {
            opacity: 35%;
          }
        }


        &.mdc-switch--selected {
          .mdc-switch__handle-track {
            transform: translateX(100%);
          }
        }
      }
    }
  }
}
