@import './variables';
@import './mixins';

.search-container {

  border: $field-border;
  height: 38px;
  background-color: theme-var($--tl-theme-surface);

  &.disabled {
    background-color: theme-var($--tl-theme-accent10);
  }

  .row > div:first-child {
    padding-right: 0;
  }

  .icon-search {
    width: 34px;

    > i [class*=fa-] {
      color: theme-var($--tl-theme-text-light);
      font-size: 1rem;
    }
  }

  input {
    height: 36px;
    border: none;
    padding-left: 5px;
    padding-right: 5px;

    &::-webkit-input-placeholder {
      font-style: italic;
    }

    &::-moz-placeholder {
      font-style: italic;
    }

    &:-ms-input-placeholder {
      font-style: italic;
    }
    &:-moz-placeholder {
      font-style: italic;
    }
  }

  .search-changed {
    button {
      @include tl-theme-button($--tl-theme-primary, $--tl-theme-dark-text);;
      border: 0;
    }
  }

  .search-button {
    @include tl-theme-button($--tl-theme-primary, $--tl-theme-dark-text);;
    border: none;
    border-left: $border-accent10;
    font-weight: 600;
    height: 36px;
    outline: none;
    border-radius: 0;
    transition: none;

    i {
      color: $border-checkbox;
      font-size: 1.25rem;
    }

    &:hover {
      border-left: $border-accent10;
    }

    &[disabled] {
      cursor: default;
    }
  }

  .icon-clear {
    padding-right: 1rem;
  }

  .clear-button {
    background: none;
    border: none;
    color: $border-checkbox;
    cursor: pointer;
    font-size: 1rem;
  }

  .tl-searching {
    ::ng-deep button {
      border: 0;
    }
  }

  .w-80 {
    width: 80%;
  }
}
