@import 'variables';

@mixin tooltipDark {
  background-color: theme-var($--tl-theme-dark-surface);
  color: theme-var($--tl-theme-dark-text);
  font: theme-var($--tl-theme-font-body3);
  font-weight: normal;
  max-width: 200px;
  border-radius: 6px;
  text-align: left;
  padding: 5px 8px;
}

.tooltip {
  font: theme-var($--tl-theme-font-body3);

  .tooltip-arrow {
    &:before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid;
    }
  }

  .tooltip-inner {
    text-align: left;
  }
}

.ng-tooltip {
  @include tooltipDark;

  position: absolute;
  z-index: 2100 !important; // tooltips must be visible for modals
  display: block;
  opacity: 0;
  -webkit-transition: opacity 200ms ease-in 600ms;
  -moz-transition: opacity 200ms ease-in 600ms;
  -o-transition: opacity 200ms ease-in 600ms;
  transition: opacity 200ms ease-in 600ms;
  max-width: 400px;

  a {
    color: theme-var($--tl-theme-link);
  }

  pre {
    color: inherit;
  }
}

.tooltip {
  z-index: 2100 !important; // tooltips must be visible for modals

  &.bs-tooltip-bottom,
  &.bs-tooltip-auto[data-popper-placement^=bottom] {
    .tooltip-arrow:before {
      bottom: 0;
      border-width: 0 .4rem .4rem;
      border-bottom-color: theme-var($--tl-theme-dark-surface);
    }
  }

  &.bs-tooltip-top,
  &.bs-tooltip-auto[data-popper-placement^=top] {
    .tooltip-arrow {
      bottom: 0;

      &:before {
        bottom: 0;
        border-width: .4rem .4rem 0;
        border-top-color: theme-var($--tl-theme-dark-surface);
      }
    }
  }

  .tooltip-inner {
    @include tooltipDark;
    max-width: 400px;

    ul {
      padding-left: 16px;
      margin-bottom: 0;
    }

    label {
      font: inherit;
      margin-bottom: 0;
    }

    span.value {
      color: theme-var($--tl-theme-dark-text);
    }
  }
}

// prevents tooltips from being positioned relative to the body when dialogs are open
.cdk-global-scrollblock .ng-tooltip {
  position: fixed;
}

.ng-tooltip .scrollable-content {
  overflow: auto;
  white-space: nowrap;
}

.ng-tooltip .scrollable-content--vertical {
  overflow-y: auto;
  max-height: 300px;
  // PORT-9183 negative margin + padding to leave the space in this container instead of the parent container
  // this container is only used in safety cover widget.
  margin-left: -16px;
  margin-right: -16px;
  margin-top: -10px;
  margin-bottom: -10px;
  padding: 10px 16px;
}

.ng-tooltip-show {
  opacity: 1;
}

.ng-tooltip::after {
  content: "";
  position: absolute;
  border-style: solid;
}

.ng-tooltip-top::after {
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: theme-var($--tl-theme-dark-surface) transparent transparent transparent;
}

.ng-tooltip-bottom::after {
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: transparent transparent theme-var($--tl-theme-dark-surface) transparent;
}

.ng-tooltip-left::after {
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent transparent transparent theme-var($--tl-theme-dark-surface);
}

.ng-tooltip-right::after {
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent theme-var($--tl-theme-dark-surface) transparent transparent;
}

.tooltip-pointer {
  width: 10px;
  height: 10px;
  position: absolute;
  margin-left: -5px;
  opacity: 0;
  border-style: solid;
  border-width: 5px;
  border-color: transparent transparent theme-var($--tl-theme-dark-surface) transparent;
}

.ng-tooltip-top .tooltip-pointer {
  bottom: -10px;
  border-top-color: theme-var($--tl-theme-dark-surface);
  border-bottom-color: transparent;
}

.ng-tooltip-bottom .tooltip-pointer {
  top: -10px;
  border-bottom-color: theme-var($--tl-theme-dark-surface);
}

.tooltip-custom-pointer:after {
  opacity: 0;
}

.tooltip-custom-pointer .tooltip-pointer {
  opacity: 1;
}

// Use the tooltip-white class to change the tooltip theme from dark to light (this used to be named tooltip-info)
.ng-tooltip.tooltip-white {
  padding: 10px 16px;
  border-radius: 0;
  background: theme-var($--tl-theme-surface);
  box-shadow: 0 0 10px rgba(0, 0, 0, .3);
  color: theme-var($--tl-theme-text);
  font: theme-var($--tl-theme-font-body2);

  word-break: normal;
  white-space: normal;
  max-width: 300px;

  &.ng-tooltip-left::after {
    border-left-color: theme-var($--tl-theme-surface);
  }

  &.ng-tooltip-right::after {
    border-right-color: theme-var($--tl-theme-surface);
  }

  &.ng-tooltip-top::after {
    border-top-color: theme-var($--tl-theme-surface);
  }

  &.ng-tooltip-bottom::after {
    border-bottom-color: theme-var($--tl-theme-surface);
  }

  &.ng-tooltip-top .tooltip-pointer {
    border-top-color: theme-var($--tl-theme-surface);
  }

  &.ng-tooltip-bottom .tooltip-pointer {
    border-bottom-color: theme-var($--tl-theme-surface);
  }

  .tooltip-title {
    font: theme-var($--tl-theme-font-h4);
    padding-bottom: 20px;
    color: theme-var($--tl-theme-primary);
    text-transform: uppercase;
  }

  .tooltip-title--simple {
    color: theme-var($--tl-theme-text);
    text-transform: capitalize;
  }

  .tooltip-section {
    margin-top: 5px;
  }

  .tooltip-link:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  p {
    word-break: normal;
    margin: 5px 0;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &.tooltip-large {
    max-width: 560px;
  }
}

.ng-tooltip.tooltip-regular-title {
  .tooltip-title {
    text-transform: none;
  }

  p {
    font: theme-var($--tl-theme-font-body2);
  }
}

.ng-tooltip.tooltip-small-title .tooltip-title {
  font: theme-var($--tl-theme-font-h6);
  text-transform: none;
}

.ng-tooltip.tooltip-integration-options {
  @media (max-width: 1399px) {
    max-width: 250px;
  }

  @media (max-width: 1199px) {
    max-width: 225px;
  }

  @media (max-width: 999px) {
    max-width: 200px;
  }
}

.ng-tooltip.tooltip-integration-options p {
  word-break: break-word;
}

.ng-tooltip.tooltip-readable,
.ng-tooltip.tooltip-readable p {
  word-break: break-word;
}

/* material tooltip overwrite */
.mat-tooltip,
.mat-mdc-tooltip {
  .mdc-tooltip__surface {
    background-color: theme-var($--tl-theme-dark-surface) !important;
    color: theme-var($--tl-theme-dark-text) !important;
    font: theme-var($--tl-theme-font-body3) !important;
    max-width: 200px;
    border-radius: 6px;
    text-align: left;
    padding: 5px 8px !important;
  }
}

// used in audit logs
.tooltip-table {
  margin-bottom: 0;

  tr {
    border-bottom: $border-accent10;

    &:last-child {
      border-bottom: 0;

      td {
        border-bottom: 0;
      }
    }
  }

  td {
    vertical-align: top;
    padding: 5px 0;

    &:nth-child(1) {
      white-space: nowrap;

      span {
        display: block;
        padding-right: 10px;
      }
    }

    &:nth-child(2) {
      white-space: nowrap;
      padding-right: 10px;
    }
  }
}

//avatar-identity tooltip
.tl-identity-details {
  p{
    margin-bottom: 0;
    word-break: break-all;
  }
}

.tl-scope-tooltip {
  max-width: 240px;
}