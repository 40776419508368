@use "sass:map";
@import "./bootstrap-pre";
@import './variables';
@import './mixins';

[data-theme="zoomlight"] tl-app-root {
  display: block;
}

.page--left-header { /* Page is 2 columns layout with header only on the left nav bar */
  padding-top: 20px;
  padding-bottom: 20px;
}

.page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0 0.75rem 0;
  gap: map.get($spacers, 4);

  .page-title {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    gap: 0.5rem;
    margin-bottom: 0;
    width: 100%;
  }
}

.page-content {
  background-color: theme-var($--tl-theme-surface);
  box-shadow: $box-shadow;
  min-height: 500px;
  padding: 25px 20px;

  @media (min-width: 1600px) {
    min-height: 800px;
  }

  tl-search-input.search {
    display: block;
    margin-bottom: 20px;
  }

  .page-header {
    min-height: 27px;
    padding: 0;
    margin-bottom: 1.5rem;
    align-items: flex-start;

    .page-title {
      /* border bottom */
      &.title-border-bottom {
        border-bottom: $border-accent10;
        padding-bottom: $gutter;
        width: 100%;
      }

      .form-group {
        font: inherit;
        width: 100%;
      }

      .form-control {
        font: inherit;
        width: 100%;
      }

      &.page-title-inline {
        display: inline;
      }

      a {
        color: theme-var($--tl-theme-text-light);
        flex: 0 0 auto;
      }

      .title-breadcrumb-item {
        margin-left: 10px;
        margin-right: 5px;
      }
    }

    .page-description {
      color: theme-var($--tl-theme-text-light);
      font: theme-var($--tl-theme-font-h3);
      font-weight: normal;
      margin-bottom: $gutter;

      &.form-group, .form-group {
        font: theme-var($--tl-theme-font-h3);
        font-weight: normal;

        .form-control {
          font: inherit;
        }
      }

      label {
        font: inherit;
      }
    }

    .page-header-main {
      width: 70%;

      .page-title {
        margin-bottom: 0.5rem;
      }
    }

    .actions,
    .labels {
      width: auto;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;

      button {
        @include management-action-button;
      }
    }
  }
}

.page-main {
  background-color: theme-var($--tl-theme-surface);
  box-shadow: $box-shadow;
  padding: 20px 0 10px 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  min-height: 588px;
  margin: 0;
}

.page-section {
  margin-bottom: 2.4rem;

  .page-section-description {
    color: theme-var($--tl-theme-text-light);
  }
}

.card {
  background-color: theme-var($--tl-theme-surface);
  box-shadow: $box-shadow;
  outline: 1px solid var(--tl-theme-accent20);
  margin-bottom: 16px;
  border: 0;
  border-radius: 0;

  &.viewer-panel, &.side-content {
    box-shadow: none;
    margin: 0;
    height: 100%;
  }

  .card-body {
    padding: 20px;
  }
}

tl-review-details {
  .card {
    &.viewer-panel, &.side-content {
      outline: 0;
    }
  }
}

html[data-theme="zoomlight"] {
  .page-main {
    @include theme-zoom-container;
  }

  .page-content {
    @include theme-zoom-container;
  }

  .card {
    @include theme-zoom-container;
    outline: 0;
  }
}