/* --------------------------------------- */
/* Theme Variables                         */
/* --------------------------------------- */

$--tl-theme-background: --tl-theme-background;
$--tl-theme-surface: --tl-theme-surface;
$--tl-theme-text: --tl-theme-text;
$--tl-theme-text-light: --tl-theme-text-light;     /* Light text color. Use in info */
$--tl-theme-dark-surface: --tl-theme-dark-surface; /* Dark surface background color */
$--tl-theme-dark-text: --tl-theme-dark-text;       /* Text color for dark surface */
$--tl-theme-primary: --tl-theme-primary;
$--tl-theme-secondary: --tl-theme-secondary;
$--tl-theme-tertiary: --tl-theme-tertiary;
$--tl-theme-quaternary: --tl-theme-quaternary;
$--tl-theme-success: --tl-theme-success;
$--tl-theme-danger: --tl-theme-danger;
$--tl-theme-warn: --tl-theme-warn;
$--tl-theme-warn2: --tl-theme-warn2;
$--tl-theme-info: --tl-theme-info;
$--tl-theme-link: --tl-theme-link;
$--tl-theme-accent: --tl-theme-accent; /* Mouseover color */
$--tl-theme-accent05: --tl-theme-accent05;
$--tl-theme-accent10: --tl-theme-accent10;
$--tl-theme-accent20: --tl-theme-accent20;
$--tl-theme-accent30: --tl-theme-accent30;
$--tl-theme-accent33: --tl-theme-accent33; /* Switch off state color */
$--tl-theme-accent40: --tl-theme-accent40;
$--tl-theme-accent50: --tl-theme-accent50;
$--tl-theme-accent60: --tl-theme-accent60;
$--tl-theme-accent70: --tl-theme-accent70;
$--tl-theme-accent80: --tl-theme-accent80;
$--tl-theme-accent90: --tl-theme-accent90;
$--tl-theme-primary-accent10: --tl-theme-primary-accent10;
$--tl-theme-primary-accent20: --tl-theme-primary-accent20;
$--tl-theme-primary-accent63: --tl-theme-primary-accent63;
$--tl-theme-primary-accent66: --tl-theme-primary-accent66;
$--tl-theme-secondary-accent10: --tl-theme-secondary-accent10;


/* Element Specific Theme Variables. Use sparingingly */
/*
 * Define theme color for header.
 * TL uses light text on dark background and zoom uses dark text on light background so cannot use generalized color scheme
 */
$--tl-theme-header-background: --tl-theme-header-background; /* Main Navigation Background */
$--tl-theme-header-background2: --tl-theme-header-background2; /* Main Navigation Background for iFrame */
$--tl-theme-header-text: --tl-theme-header-text;               /* Main Navigation Text Color */
$--tl-theme-search-highlight: --tl-theme-search-highlight;
$--tl-theme-table-header: --tl-theme-table-header;
$--tl-theme-table-header-text: --tl-theme-table-header-text;
$--tl-theme-table-body-text: --tl-theme-table-body-text;
$--tl-theme-table-header-border: --tl-theme-table-header-border;
$--tl-theme-table-body-border: --tl-theme-table-body-border;
$--tl-theme-blockquote: --tl-theme-blockquote;

/* Font specific variables */
$--tl-theme-font-size-base: --tl-theme-font-size-base;
$--tl-theme-font-large: --tl-theme-font-large;
$--tl-theme-font-h1: --tl-theme-font-h1;
$--tl-theme-font-h2: --tl-theme-font-h2;
$--tl-theme-font-h3: --tl-theme-font-h3;
$--tl-theme-font-h4: --tl-theme-font-h4;
$--tl-theme-font-h5: --tl-theme-font-h5;
$--tl-theme-font-h6: --tl-theme-font-h6;
$--tl-theme-font-body: --tl-theme-font-body;
$--tl-theme-font-label: --tl-theme-font-label;
$--tl-theme-font-body2: --tl-theme-font-body2;
$--tl-theme-font-caption: --tl-theme-font-caption;
$--tl-theme-font-body3: --tl-theme-font-body3;
$--tl-theme-font-table: --tl-theme-font-table;
$--tl-theme-font-table-body: --tl-theme-font-table-body;
$--tl-theme-font-small-bold: --tl-theme-font-small-bold;


/* --------------------------------------- */
/* Common Color Definitions                */
/* --------------------------------------- */
$white-hsl: 0, 0%, 100%;
$black-hsl: 0, 0%, 0%;
