@use "sass:math";

/* Ref: https://bcodes.io/blog/post/theming-css-variables-in-scss */
@function theme-var($key, $fallback: null) {
  @if ($fallback) {
    @return var($key, $fallback);

  } @else {
    @return var($key);
  }
}

/*
 * Generate hsla() call with hue, saturation, and lightness variables with and opacity value.
 * $color - theme variable name (Example: --tl-theme-background)
 * $opicity - alpha value in percentage
 */
@function theme-alpha($color, $opacity) {
  $color-h: var(#{$color+'-h'});
  $color-s: var(#{$color+'-s'});
  $color-l: var(#{$color+'-l'});
  @return hsla($color-h, $color-s, $color-l, $opacity);
}

/*
 * Add/Subtract multiplier percent from lightness value and return a new HSL value using the hue, saturation, and lightness variables
 * $color - theme variable name (Example: --tl-theme-background)
 * $lightnessMultiplier - lightnessMultiplier in percentage (+ve value to lighten, -ve value to darken)
 */
@function theme-lightness($color, $lightnessMultiplier) {
  $multiplier: math.div(($lightnessMultiplier + 100%), 100%);
  $color-h: var(#{$color+'-h'});
  $color-s: var(#{$color+'-s'});
  $color-l: var(#{$color+'-l'});
  @return hsl($color-h, $color-s, calc(#{$color-l} * #{$multiplier}));
}

/**
 * Build args for material define-typography-config for a given font type from our theme
 *
 * This depends on all the font's properties being individually set by spreadMap when a theme
 * is defined. Can be passed directly into define-typography-config with the spread operator.
 * This doesn't use the theme-var, since we aren't exposing scss variables for every individual
 * font property, just the shorthand "font" property.
 */
@function mat-typography-for-font($font-type) {
  @return (
    font-family: var(--tl-theme-font-#{$font-type}-family),
    font-weight: var(--tl-theme-font-#{$font-type}-weight),
    font-size: var(--tl-theme-font-#{$font-type}-size),
    line-height: var(--tl-theme-font-#{$font-type}-line-height),
  )
}