@import './variables';
@import './mixins';
@import 'themes/theme-mixins';
@import 'themes/theme-variables';

$panel-header-height: 36px;

.mat-accordion:not(.integration-health__accordion, .dashboard-unified-capture__accordion) {
  .mat-expansion-panel {
    overflow: visible;
    box-shadow: none;
    width: 100%;

    &:not([class*=mat-elevation-z]) {
      box-shadow: none;
    }

    .mat-expansion-panel-header {
      padding: 0 0 0 15px;
      line-height: 24px;
      height: $panel-header-height;

      &.mat-expanded {
        height: $panel-header-height;
      }

      @include media-breakpoint-up(sm) {
        height: auto;

        &.mat-expanded {
          height: auto;
        }
      }
    }

    .mat-expansion-panel-content.ng-animating {
      overflow: hidden;
    }

    .mat-expansion-panel-body {
      padding: 0 1px 0 14px !important;
    }

    &.expansion-panel {
      &.mat-expanded {
        box-shadow: none;
      }

      .mat-expansion-panel-header {
        padding-right: 13px;
      }
    }

    &.mat-expansion-panel-spacing {
      margin: 0;
    }
  }

  &.search-query-panel {
    .mat-expansion-panel.expansion-panel {
      background-color: transparent;

      .mat-expansion-panel-header {
        padding: 0 30px 0 22px;
        position: relative;
        z-index: 2;

        @media (max-width: 440px) {
          padding: 0 8px 0 0px;
        }

        @media (max-width: 960px) {
          padding: 0 16px 0 8px;
        }

        .mat-content {
          margin: 0;
          padding: 0;
          display: flex;
          overflow: visible;
          flex-flow: row nowrap;
          justify-content: space-between;
          align-items: center;
          cursor: default;
        }

        button.panel-title {
          position: relative;
          top: 0;
          left: 0;
          border: $border-accent20;
          border-top: 0;
          border-radius: 0 0 8px 8px;
        }

        &.mat-expanded {

          @media (min-width: 960px) {
            button.panel-title {
              position: absolute;
            }
          }
        }
      }

      .mat-expansion-panel-body {
        padding: 0 30px !important;

        @media (max-width: 440px) {
          padding: 0 8px !important;
        }

        @media (max-width: 960px) {
          padding: 0 14px !important;
        }
      }
    }
  }

  /* customization for search applied terms & filters */
  &.search-applied-terms-filters-wrapper {
    margin-top: 8px;
    display: block;

    mat-expansion-panel,
    mat-expansion-panel * {
      transition: height 100ms cubic-bezier(0.4, 0, 0.2, 1);
    }

    .mat-expansion-panel.expansion-panel {
      border-radius: 0;
      min-height: 32px;

      .mat-expansion-panel-header {
        padding: 0;
        border-top: $border-accent20;
        border-bottom: 0;
        border-radius: 0;
        height: 0;

        &.mat-expanded {
          height: 36px;

          @include media-breakpoint-up(lg) {
            height: 0;
          }
        }

        .mat-content {
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          width: auto;
          position: absolute;
          top: 0;
          left: 0;

          .panel-title {
            margin-bottom: 0;
          }
        }
      }

      .mat-expansion-panel-body {
        padding: 0 1px 0 1px !important;
      }
    }
  }
}

// Unified Capture Dashboard and Integration Health Widget
.mat-accordion {
  &.integration-health__accordion, &.dashboard-unified-capture__accordion {
    .mat-expansion-panel {
      background: none;
      box-shadow: none;
    }

    .mat-expansion-panel-body {
      padding: 0;
    }
  }
}
