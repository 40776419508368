@import 'variables';

.back-wrapper::after {
  content: "";
  display: block;
  clear: both;
}

.wizard-back {
  background-color: transparent;
  border: none;
  color: theme-var($--tl-theme-text);
  cursor: pointer;
  float: left;
  margin-left: -26px;
  margin-top: -3px;
  width: 1.625rem;
}

.wizard-heading {
  margin-bottom: 20px;
}

.wizard-main {
  margin: 0 auto;
  padding-top: 10px;
  width: 300px;
}

.wizard-btn {
  width: 100%;
  margin-bottom: $gutter;
  text-align: left;

  img {
    max-height: 30px;
  }

  .sub-title {
    color: $border-checkbox;
    font: theme-var($--tl-theme-font-body2);
    margin-bottom: 0;
  }

  .title {
    margin-bottom: 5px;
  }
}
