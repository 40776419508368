@import 'themes/theme-variables';
@import 'themes/theme-mixins';

/* --------------------------------------- */
/* APPLICATION VARIABLES                   */
/* --------------------------------------- */

// some new colors for edited chat messages
$icon-grey: theme-var($--tl-theme-text);

$chart-y-axis-legend: theme-var($--tl-theme-accent40);

$border-checkbox: theme-var($--tl-theme-accent40);

$checkbox-background-color: theme-var($--tl-theme-secondary);

$box-shadow: 0 0 4px 0 theme-var($--tl-theme-accent20);

/* --------------------------------------- */
/* BOOTSTRAP OVERRIDES                     */
/* --------------------------------------- */

$topbar-height: 50px;

$default-form-input-height: 35px;

$font-weight-normal: 400;
$font-weight-semibold: 500;
$fw-bold: 700;

$gutter: 10px;
$gutter-2x: $gutter * 2;
$gutter-3x: $gutter * 3;

$grid-breakpoints: (
  xs: 0,
  sm: 800px,
  md: 1000px,
  lg: 1200px,
  xl: 1400px,
  xxl: 1700px,
  xxxl: 2000px
);

$container-max-widths: (
  xs: 750px,
  sm: 950px,
  md: 1150px,
  lg: 1350px,
  xl: 1600px,
  xxl: 1900px,
  xxxl: 2200px
);

$zindex-modal: 1050;

$btn-border-radius:     8px;
$btn-border-radius-sm:  0.2rem;
$btn-border-radius-lg:  8px;

/* --------------------------------------- */
/* TOUR DEFINITIONS                        */
/* --------------------------------------- */

$nav-bar-z-index: $zindex-modal - 20;
$tour-dimming-z-index: $nav-bar-z-index + 1;
$popper-z-index: $tour-dimming-z-index + 1 !important;
$tour-target-z-index: $tour-dimming-z-index + 2;
$tour-dropdowns-z-index: $tour-dimming-z-index + 3;

/* Other colors */
$filter-highlight-color: theme-alpha($--tl-theme-text, 5%);

/* Border */
$border-accent10: 1px solid theme-var($--tl-theme-accent10);
$border-accent10-thick: 3px solid theme-var($--tl-theme-accent10);
$border-accent20: 1px solid theme-var($--tl-theme-accent20);
$border-accent30: 1px solid theme-var($--tl-theme-accent30);

/* Field */
$field-border-color: theme-var($--tl-theme-accent20);
$field-border: $border-accent20;
$field-padding: 0.375rem 0.75rem;
$field-checkbox-border: 1px solid theme-var($--tl-theme-accent30);
$field-border-radius: 4px;

/* Table */
$table-container-border: $border-accent20;
$table-header-bg: theme-var($--tl-theme-table-header);
$table-header-color: theme-var($--tl-theme-table-header-text);
$table-border: $border-accent20;
$table-font: theme-var($--tl-theme-font-table);
$table-body-font: theme-var($--tl-theme-font-table-body);
$table-text-color: theme-var($--tl-theme-table-body-text);
$table-header-border: 2px solid theme-var($--tl-theme-table-header-border);
$table-body-border: 1px solid theme-var($--tl-theme-table-body-border);

/* Search Input Height */
$search-compact-height: 28px;
$search-medium-height: 34px;
$search-normal-height: 38px;

/* Zoom Theme Specific Variables That We want to Use */
$ztl-menu-border-radius: 8px;
