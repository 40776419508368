@import 'variables';

table,
.table, .mat-mdc-table {
  color: inherit;
  font: theme-var($--tl-theme-font-body2);
  position: relative;
  margin-bottom: .5rem;

  thead th, .mat-mdc-header-cell {
    background: $table-header-bg;
    color: $table-header-color;
    font: $table-font;
    vertical-align: middle;
    margin: 0;
    padding: 0.5rem 1rem;
    position: sticky;
    top: 0;
    z-index: 3;
    border: 0;
    border-bottom: $table-header-border;
    outline: 0;

    tl-info-tooltip {
      margin-left: 5px;
    }
  }

  tbody {
    tr {
      &.selected {
        background-color: theme-var($--tl-theme-accent20);

        td {
          font-weight: bold;
          margin: 0;
        }
      }
    }

    td {
      color: $table-text-color;
      font: $table-body-font;
      cursor: default;
      padding: 0.3125rem 1rem;
      border: none;
      border-bottom: $table-body-border;
      vertical-align: middle;

      &.actions-col {
        padding: 0 !important;
      }

      .cell-content {
        &:hover {
          svg, i {
            color: theme-var($--tl-theme-accent30);
          }
        }
      }

      .ng-select {
        .ng-select-container {
          min-height: 32px;

          span {
            line-height: 1rem;
          }
        }
      }
    }

    & + tbody {
      border-top: 0;
    }
  }
}

// zoom theme
html[data-theme="zoomlight"] {
  table,
  .table {
    thead {
      th {
        border-bottom-width: 1px;
        padding-top: .8125rem;
        padding-bottom: .8125rem;
      }
    }

    td {
      padding-top: .6875rem;
      padding-bottom: .6875rem;
    }

    tl-context-menu > button {
      color: theme-var($--tl-theme-tertiary);
    }
  }
}
