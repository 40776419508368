@use "sass:map";
@import "./bootstrap-pre";
@import './variables';

@keyframes chat-row-highlight-change-border-color {
  from {
    border-color: theme-var($--tl-theme-accent50);
    background-color: theme-var($--tl-theme-background);
  }
  to {
    border-color: theme-var($--tl-theme-background);
    background-color: transparent;
  }
}

.chat-viewer-container {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  border: $border-accent10-thick;
  max-height: 70vh; /* set the max */

  .chat-row {
    padding: 4px map.get($spacers, 1);
    border: 1px transparent solid;
    border-left: 4px solid transparent;
    cursor: pointer;

    ::ng-deep .tl-alert-form {
      margin-bottom: 0;
    }

    &.selected {
      border-left: 4px solid theme-var($--tl-theme-primary);
    }

    &.highlighted{
      animation-name: chat-row-highlight-change-border-color;
      animation-duration: 5s;
    }

    &.message-in-thread {
      background-color: theme-var($--tl-theme-accent);
      margin-left: 30px;
    }

    &:hover, &.highlighted, &.selected {
      background-color: theme-var($--tl-theme-background);

      .message-deleted-indicator {
        visibility: hidden;
      }

      .risk-score-icon.chat-icon.chat-context-menu-icon {
        visibility: visible;
      }
    }

    .message-thread-link {
      font: theme-var($--tl-theme-font-body3);
      cursor: pointer;
      visibility: hidden;
    }

    &:hover{
      .message-thread-link {
        visibility: visible;
      }
    }
  }

  .avatar-row {
    background-color: theme-var($--tl-theme-surface);
    padding: 8px;
  }

  .sticky {
    position: sticky;
    top: 0;
    z-index: 3;
  }

  .participant-avatar {
    display: block;
    margin: auto;
  }

  .message-reactions {
    margin: 0;
    margin-top: 4px;
    padding: 0;
    list-style: none;

    li {
      display: inline-block;
      margin: 0 4px 4px 0;
      padding: 2px 8px;
      background-color: theme-var($--tl-theme-accent10);
      border-radius: 4px;
      cursor: default;

      &:hover {
        background-color: theme-var($--tl-theme-accent20);
      }
    }
  }

  .message-subtitle {
    visibility: visible;
    color: theme-var($--tl-theme-accent40);
  }

  .message-title .message-subtitle {
    visibility: visible;
  }

  .message-attachment {
    margin: 0;
    padding: 7px 15px;
    background-color: theme-var($--tl-theme-accent10);
    color: theme-var($--tl-theme-accent60);
    border-radius: 5px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
    width: fit-content;
    border: none;
    //for stream viewer
    position: relative;
    z-index: 2;

    &:hover {
      background-color: theme-var($--tl-theme-accent20);
    }
  }

  .out-of-scope {
    background-color: theme-var($--tl-theme-background);

    .chat-row {
      cursor: default;
    }

    .message-attachment {
      background-color: theme-var($--tl-theme-accent20);

      &:hover {
        background-color: theme-var($--tl-theme-accent10);
      }
    }

    .participant-avatar {
      background-color: theme-var($--tl-theme-accent50) !important;
    }

    .chat-divider {
      border-top: $border-accent10-thick;
    }

    .chat-divider-title span {
      background-color: theme-var($--tl-theme-background);
    }
  }

  .message-group {
    padding: 4px 0;

    &.last-primary-message-group {
      border-bottom: 4px solid theme-var($--tl-theme-background);
      padding-bottom: 0;
    }
  }

  .risk-tag {
    background-color: theme-var($--tl-theme-danger);
    color: theme-var($--tl-theme-dark-surface);
    border-radius: 5px;
    padding: 2px 8px;
    font: theme-var($--tl-theme-font-body2);
    font-weight: bold;
  }

  .chat-message-delete-status {
    background-color: theme-alpha($--tl-theme-danger, 10%);
    padding: 4px 8px;
    margin: 2px 0 4px 0;

    i, fa-icon {
      color: theme-var($--tl-theme-danger);
      margin-right: 6px;
    }
  }

  .chat-message-edit-status {
    background-color: theme-alpha($--tl-theme-secondary, 10%);
    padding: 4px 8px;
    margin: 2px 0 4px 0;

    i, fa-icon {
      color: theme-var($--tl-theme-text);
      margin-right: 6px;
    }

    button.tl-btn-secondary {
      float: right;
      padding: 0 10px;
    }
  }

  .chat-divider {
    border-top: $border-accent10-thick;
    margin: 18px auto 0 auto;
    width: 94%;
  }

  .chat-divider-title {
    text-align: center;
    position: relative;
    top: -11px;

    span {
      background-color: theme-var($--tl-theme-surface);
      padding: 2px 8px;
      color: theme-var($--tl-theme-accent30);
      font-weight: bold;
    }
  }

  hr {
    border-top: $border-accent10;
  }

  .left-col-content {
    text-align: center;
    position: sticky;
    top: 55px;
  }

  .dark-theme {
    background-color: theme-var($--tl-theme-dark-surface);
    color: theme-var($--tl-theme-accent10);

    .chat-row:hover {
      background-color: theme-var($--tl-theme-accent80);
    }

    .chat-divider {
      border-top: 2px solid theme-var($--tl-theme-accent80);
    }

    .chat-divider-title span {
      background-color: theme-var($--tl-theme-dark-surface);
    }

    .message-attachment {
      background-color: theme-var($--tl-theme-accent60);
      color: theme-var($--tl-theme-accent20);

      &:hover {
        background-color: theme-var($--tl-theme-accent70);
      }
    }
  }

  .chat-viewer-inline-image {
    max-width: 90%;
    border-radius: 6px;
    // for stream viewer
    position: relative;
    z-index: 2;
  }

  .stream-bubble {
    background: theme-var($--tl-theme-background);
    padding: 8px 12px;
    border-radius: 6px;
    position: relative;
    z-index: 2;
  }

  .chat-status-container {
    text-align: center;
    font-weight: bold;
    margin: 0 auto;
    width: 100%;

    .chat-conversation-marker {
      margin: 0;
      color: theme-var($--tl-theme-accent60);
      font: theme-var($--tl-theme-font-h1);
    }

    .btn {
      width: 25%;
      margin: 0 auto;
      display: block;
      cursor: pointer;
    }
  }

  .risk-score-icon:not(.MULTIPLE) {
    font-size: 1.1rem;
    height: 1.1rem;
    width: 1.1rem;
    line-height: 0;
  }

  .risk-score-icon.chat-icon {
    display: inline-block;
    padding-top: 0;
    margin: 0 2px;
  }

  .risk-score-icon.chat-icon.chat-context-menu-icon {
    display: inline-block;
    visibility: hidden;
    padding: 0 4px;
    color: theme-var($--tl-theme-text-light);
  }

  .message-content {
    white-space: pre-line;
    word-break: break-word;
    word-wrap: break-word; /* IE11 */

    &.message-content--html {
      white-space: normal;

      p {
        margin: unset;
      }

      a img {
        width: 100%;
      }
    }

    &.message-content--text {
      span > p {
        margin: unset;
      }
    }

    .highlighted-character-offset {
      background-color: theme-var($--tl-theme-warn2);
    }
  }

  .message-content-bars span {
    color: transparent;
    background: #ddd;
    font-size: 80%; // decrease height of message content bars
    letter-spacing: 1.5px; // restore width of content at font-size: 100%
  }

  tl-media-viewer {
    flex: 1;
  }

  code {
    display: block;
    background: theme-var($--tl-theme-background);
    border-radius: .25rem;
    border: 1px solid theme-var($--tl-theme-accent20);
    border-top-width: .25rem;
    border-top-color: theme-var($--tl-theme-primary);
    padding: .75rem;
    margin-top: .25rem;
    margin-bottom: .25rem;
    white-space: pre-wrap;
  }

  tl-chat-message-row {
    table, tr, td, th {
      padding: 3px;
      background: theme-var($--tl-theme-background);
    }

    th {
      font-weight: bold;
    }

    .selected, :hover {
      table, tr, td, th {
        background: theme-var($--tl-theme-surface);
      }
    }
  }

  blockquote {
    background-color: theme-var($--tl-theme-blockquote);
    padding: 6px 6px 6px 12px;
    border: $border-accent20;
    border-left: 4px solid theme-var($--tl-theme-accent20);
    border-radius: 5px;
  }
}
