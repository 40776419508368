@import 'bootstrap/scss/mixins';
@import 'variables';
@import 'mixins';

// styles for wave surfer waveform. These are on third party inserted element, hence the global styles

.media-viewer-container {
  .waveform {
    background-color: theme-var($--tl-theme-accent10);
    ::part(progress){
      background-color: #c89ce2;
    }
    height: 30px;
    width: 100%;
    top: 30px;
  }
  .waveform-enabled {
    wave {
      top: 30px;
      height: 30px !important;
      cursor: pointer!important;
      background-color: theme-var($--tl-theme-accent10);
      & > wave {
        background-color: #c89ce2;
      }
    }

    .vjs-fullscreen {
      .waveform {
        top: calc(100vh - 30px);
      }
    }

    .video-js.vjs-fullscreen {
      .vjs-control-bar {
        bottom: 30px;
      }
    }
  }

  //on control bar button
  .vjs-control-bar {
    .icon-waveform {
      background-image: url(../assets/images/icon-waveform.svg);
      background-repeat: no-repeat;
      background-size: 22px;
      height: 22px;
    }
  }
}
