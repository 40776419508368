ui-switch {
  align-items: center;
  display: flex;

  .switch {
    border: 1px solid theme-var($--tl-theme-accent20);
    background: theme-var($--tl-theme-accent20);

    &.checked {
      background: theme-var($--tl-theme-primary);
    }

    &.switch-small {
      border-radius: 10px;
      box-shadow: none;
      height: 15px;
      min-width: 26px;

      small {
        box-shadow: none;
        height: 9px;
        left: 2px;
        top: 3px;
        width: 9px;
      }

      &.checked {
        small {
          left: 14px;
        }
      }
    }
  }
}

html[data-theme="zoomlight"] {
  ui-switch {
    .switch {
      background: theme-var($--tl-theme-accent30);

      &.checked {
        background: theme-var($--tl-theme-primary);
      }

      &.disabled {
        opacity: 35%;
      }

      &.switch-small {
        height: 20px;
        min-width: 36px;
        border: 0;

        small {
          height: 16px;
          left: 2px;
          right: auto;
          top: 2px;
          width: 16px;
          box-shadow: 0 1px 1px 0 theme-alpha($--tl-theme-accent90, 0.4),
                      0 1px 2px 0 theme-alpha($--tl-theme-accent90, 0.2);
        }

        &.checked {
          small {
            left: auto;
            right: 2px;
          }
        }
      }
    }
  }
}