@import 'themes/theme-variables';
@import 'themes/theme-mixins';

.timepicker {

  .timepicker__header {
    background-color: theme-var($--tl-theme-primary) !important;
  }

  .clock-face__clock-hand {
    background-color: theme-var($--tl-theme-primary) !important;

    &::after {
      background-color: theme-var($--tl-theme-primary) !important;
    }

    &::before {
      border-color: theme-var($--tl-theme-primary) !important;
    }
  }

  .clock-face__number {
    > span.active {
      background-color: theme-var($--tl-theme-primary) !important;
    }
  }

  .timepicker__actions {
    display: flex !important ;
    flex-direction: row !important;
    justify-content: space-between !important;

    // TODO: select first button for cancel styles
    .timepicker-button:nth-of-type(1) {
      cursor: pointer !important;
      background-color: theme-var($--tl-theme-primary) !important;
      border-color: theme-var($--tl-theme-primary) !important;
      color: theme-var($--tl-theme-dark-text) !important;
      font: theme-var($--tl-theme-font-h4) !important;
    }
  }
}
