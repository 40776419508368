@import '../theme-variables';
@import '../theme-core';

$tl-font-family: 'OpenSans', sans-serif;

// based on browser font size of 16px
// These are defined as a list, so that we can reuse the individual parts in our material theme
$tl-font-base: normal, 400, 0.875rem, 1.5, $tl-font-family;
$tl-font-small-bold: normal, 700, .75rem, 1.2, $tl-font-family; // 12px bold
$tl-font-large: normal, 400, 1.875rem, 1.5, $tl-font-family; // 30px
$tl-font-h1: normal, 700, 1.25rem, 1.5, $tl-font-family;   // 20px
$tl-font-h2: normal, 700, 1.125rem, 1.5, $tl-font-family;  // 18px
$tl-font-h3: normal, 700, 1rem, 1.5, $tl-font-family;      // 16px
$tl-font-h4: normal, 600, 0.875rem, 1.5, $tl-font-family;  // 14px /* Weight 600 for media item name */
$tl-font-h5: normal, 700, 0.8125rem, 1.5, $tl-font-family; // 13px
$tl-font-h6: normal, 600, 0.75rem, 1.5, $tl-font-family;   // 12px /* Weight 600 for simple media item name */
$tl-font-label: normal, 600, 0.875rem, 1.5, $tl-font-family;  // 14px
$tl-font-body2: normal, 400, 0.75rem, 1.5, $tl-font-family;   // 12px
$tl-font-caption: normal, 700, 0.6875rem, 1.5, $tl-font-family;  // 11px
$tl-font-body3: normal, 400, 0.6875rem, 1.5, $tl-font-family;    // 11px
$tl-font-table: normal, 700, .75rem, 1.2, $tl-font-family; // 12px bold
$tl-font-table-body: normal, 400, .75rem, 1.2, $tl-font-family; // 12px

/* Define colors in HSL.
 * Use https://convertingcolors.com/hex-color-FFA106.html to convert HEX to HSL
 */

/* The higher the number, the darker the color */
$tl-gray-05: 0, 0%, 95%; /* #f2f2f2; Light Gray */
$tl-gray-10: 210, 16%, 93%; // #E9ECEF;
$tl-gray-20: 0, 0%, 79%;    // #C9C9C9;
$tl-gray-30: 0, 0%, 67%;    // #ABABAB;
$tl-gray-40: 0, 0%, 60%;    // #999999;
$tl-gray-50: 0, 0%, 45%;    // #737373;
$tl-gray-60: 0, 0%, 40%;    // #666666;
$tl-gray-70: 0, 0%, 29%;    // #4A4A4A;
$tl-gray-80: 206, 19%, 26%; // #374550;
$tl-gray-100: 223, 47%, 7%; // #0A0F1C; /* Dark Gray */

$tl-green-70: 158, 89%, 38%; // #0BB676;
$tl-green-10: 143, 50%, 97%; // #f3fbf6;

$tl-blue-70: 207, 89%, 38%;  // #0B69B6;
$tl-blue-60: 207, 70%, 57%;  // #4699DE
$tl-blue-20: 207, 89%, 93%;  // #ddeffd;

$tl-purple-70: 264, 60%, 42%; // 5F2BAB

$tl-red-80: 0, 71%, 47%;     // #CE2323;
$tl-orange-50: 37, 100%, 51%; // #ffa106;

$tl-yellow-30: 49, 100%, 61%; // #ffd938;
$tl-yellow-50: 47, 100%, 51%; // #FFC905;

$theme-thetalakelight: (
  "color": (
    "background": $tl-gray-05, /* background color of the viewport */
    "surface": $white-hsl,     /* background color of the content container */
    "text": $tl-gray-70,
    "text-light": $tl-gray-50,
    "dark-surface": $tl-gray-70,
    "dark-text": $white-hsl,
    "primary": $tl-green-70,
    "secondary": $tl-blue-70,
    "tertiary": $tl-gray-70,
    "quaternary": $tl-purple-70,
    "success": $tl-green-70,
    "danger": $tl-red-80,
    "warn": $tl-orange-50,
    "warn2": $tl-yellow-50,
    "info": $tl-blue-60,
    "link": $tl-blue-70,
    "accent": $tl-green-10,  /* Hover color for some items */
    "accent05": $tl-gray-10,
    "accent10": $tl-gray-10, /* Field / button border color */
    "accent20": $tl-gray-20,
    "accent30": $tl-gray-30,
    "accent33": $tl-gray-30,
    "accent40": $tl-gray-40,
    "accent50": $tl-gray-50,
    "accent60": $tl-gray-60,
    "accent70": $tl-gray-70,
    "accent80": $tl-gray-80,
    "accent90": $tl-gray-100,
    "primary-accent10": $tl-green-70,
    "primary-accent20": $tl-green-70,
    "primary-accent63": $tl-green-70,
    "primary-accent66": $tl-green-70,
    "secondary-accent10": $tl-blue-70,
    "header-background": $tl-gray-100,
    "header-background2": $tl-gray-100,
    "header-text": $white-hsl,
    "search-highlight": $tl-yellow-30,
    "table-header": $tl-gray-70,
    "table-header-text": $white-hsl,
    "table-body-text": $tl-gray-100,
    "table-header-border": $tl-gray-20,
    "table-body-border": $tl-gray-10,
    "blockquote": $tl-blue-20,
  ),
  "font": (
    "large": $tl-font-large,
    "h1": $tl-font-h1,
    "h2": $tl-font-h2,
    "h3": $tl-font-h3,
    "h4": $tl-font-h4,
    "h5": $tl-font-h5,
    "h6": $tl-font-h6,
    "body": $tl-font-base,
    "label": $tl-font-label,
    "body2": $tl-font-body2,
    "caption": $tl-font-caption,
    "body3": $tl-font-body3,
    "table": $tl-font-table,
    "table-body": $tl-font-table-body,
    "small-bold": $tl-font-small-bold,
  )
);

@include theme-config('thetalakelight', $theme-thetalakelight);
