@import './variables';
@import './mixins';

.participant-avatar {
  background-color: theme-var($--tl-theme-text-light);
  cursor: default;
  display: inline-block;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 100%;

  .participant-avatar--initials {
    text-align: center;
    color: theme-var($--tl-theme-dark-text);
    font-weight: bold;
    display: block;
    padding-top: 11px;
    line-height: 1.25;
    text-transform: uppercase;
  }
}

::ng-deep {
  button.action-btn {
    border: 0;
    border-radius: 4px;
    float: right;
    font-size: 1rem;
    line-height: 1;
    outline: 0;
    padding: 0;
    background-color: transparent;
  }
}

::ng-deep {
  .check {
    background: theme-var($--tl-theme-primary);
    color: theme-var($--tl-theme-dark-text);
    cursor: normal;
    padding: 2px 4px;
    border-radius: 4px;
  }
}

::ng-deep {
  .flag {
    background: theme-var($--tl-theme-danger);
    color:theme-var($--tl-theme-dark-text);
    cursor: normal;
    padding: 2px 4px;
    border-radius: 4px;
  }
}

::ng-deep .flag svg,
::ng-deep .check svg {
  max-width: 12px;
}

.load-more-risk-logs-btn-container {
  padding-bottom: 5px;
  padding-right: 5px;
  padding-top: 5px;
  text-align: center;

  button {
    background-color: theme-var($--tl-theme-surface);
    border: none;
    color: theme-var($--tl-theme-text);
    cursor: pointer;
    font: theme-var($--tl-theme-font-h6);
    margin-top: 10px;
    text-transform: uppercase;
    width: 100%;
  }
}
