@import 'variables';
@import 'mixins';

context-menu-content.ngx-contextmenu {
  @include dropdownmenu;

  .ngx-context-menu-item {
    @include dropdownmenuitem();
  }
}

html[data-theme="zoomlight"] {
  context-menu-content.ngx-contextmenu {
    @include theme-zoom-dropdownmenu();

    .ngx-context-menu-item {
      @include theme-zoom-dropdownmenuitem();
    }
  }
}