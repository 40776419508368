@import 'variables';

p-calendar ::ng-deep {
  .ui-inputtext.ui-state-focus, .ui-inputtext:focus,
  .ui-inputtext:enabled:focus:hover {
    border-color: theme-var($--tl-theme-primary);
    box-shadow: none;
  }

  .ui-datepicker {
    box-shadow: none;

    .ui-datepicker-buttonbar {
      background: theme-var($--tl-theme-surface);
      padding: .4em .5em;

      .ui-button.ui-state-default {
        background-color: theme-var($--tl-theme-primary);
        border-color: theme-var($--tl-theme-primary);
        color: theme-var($--tl-theme-dark-text);
      }

      .ui-button-text {
        font: theme-var($--tl-theme-font-h4);
      }
    }
  }
}

p-calendar {
  display: block;
  width: 100%;

  ::ng-deep > .ui-calendar {
    width: 100%;
  }

  ::ng-deep input {
    padding-left: 15px;
    width: 100%;
  }

  ::ng-deep .ui-calendar-button {
    background: theme-var($--tl-theme-primary);
    border-color: theme-var($--tl-theme-primary);
    cursor: pointer;
    margin: 0;
    right: 0;

    &:hover,
    &:focus,
    &:active,
    &:enabled:hover,
    &:enabled:active {
      background-color: theme-lightness($--tl-theme-primary, -10%);
      border-color: theme-lightness($--tl-theme-primary, -10%)
    }

    & > span {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      padding-left: 2px;
    }
  }

  ::ng-deep .ui-datepicker {
    width: 100%;
  }
}

.tl-has-danger {
  p-calendar {
    ::ng-deep input {
      border: 1px solid theme-var($--tl-theme-danger);
    }

    ::ng-deep .ui-calendar-button {
      background: theme-var($--tl-theme-danger);
      border-color: theme-var($--tl-theme-danger);
    }
  }
}

table.ui-datepicker-calendar {
  tbody tr:hover {
    background-color: transparent;
  }
}
