// Ref: https://codyhouse.co/blog/post/how-to-combine-sass-color-functions-and-css-variables
@mixin defineColorHSL($color, $hue, $saturation, $lightness) {
  #{$color}: unquote("hsl(#{$hue}, #{$saturation}, #{$lightness})");
  #{$color}-h: #{$hue};
  #{$color}-s: #{$saturation};
  #{$color}-l: #{$lightness};
}

/**
 * Define css vars for all font properties, including the shorthand. These are used by
 * material-theme.scss to keep it in-line with our theme definitions.
 */
@mixin defineFontProperties($font, $style, $weight, $size, $line-height, $family) {
  #{$font}: unquote("#{$style} #{$weight} #{$size}/#{$line-height} #{$family}");
  #{$font}-style: $style;
  #{$font}-weight: $weight;
  #{$font}-size: $size;
  #{$font}-line-height: $line-height;
  #{$font}-family: $family;
}

@mixin theme-config($themename, $map: ()) {
  html[data-theme='#{$themename}'] {
    @include spread-map($map);
  }
}

@mixin spread-map($map: ()) {
  @each $group, $value in $map {
    @if $group == "color" {
      @each $type, $typeValue in $value {
        // set variable --tl-theme-background: [typeValue];
        @include defineColorHSL(--tl-theme-#{$type}, $typeValue...);
      }
    } @else if $group == "font" {
      @each $type, $typeValue in $value {
        @include defineFontProperties(--tl-theme-font-#{$type}, $typeValue...);
      }
    } @else {
      @each $type, $typeValue in $value {
        // set variable --tl-theme-group-type: [typeValue];
        --tl-theme-#{$group}-#{$type}: #{$typeValue};
      }
    }
  }
}
