@import 'variables';
@import "@ng-select/ng-select/themes/default.theme.css";

.ng-select {

  .ng-select-container .ng-value-container {
    .ng-placeholder {
      @include placeholder-style;
    }

    .ng-input > input {
      padding: 0;
    }
  }

  .ng-clear-wrapper .ng-clear {
    margin-top: 3px;
  }

  &.ng-select-multiple {
    .ng-select-container .ng-value-container {
      .ng-value {
        background-color: theme-var($--tl-theme-accent);
        border-color: theme-var($--tl-theme-primary);
        border: 1px solid theme-var($--tl-theme-primary);
        color: theme-var($--tl-theme-text);
        display: flex;
        font: theme-var($--tl-theme-font-body2);

        .ng-value-label {
          white-space: normal;

          &:hover {
            background-color: theme-lightness($--tl-theme-primary, 98%);
          }
        }

        .ng-value-icon {
          display: flex;
          align-items: center;

          &.left {
            border-right: 1px solid theme-var($--tl-theme-primary);
          }

          &.right {
            border-left: 1px solid theme-var($--tl-theme-primary);
          }

          &:hover {
            background-color: theme-lightness($--tl-theme-primary, 54%);
          }
        }
      }

      .ng-placeholder {
        top: 6px;
      }
    }
  }

  &.ng-select-disabled > .ng-select-container {
    background-color: theme-var($--tl-theme-accent10);
  }
}

body > .ng-dropdown-panel { /* targeted so we don't use !important */
  z-index: 2000; /* set to same index as dialog and tooltip so that the panel is not above the tooltip */
}

ng-dropdown-panel.ng-dropdown-panel {
  .ng-dropdown-panel-items {
    max-height: 200px !important;

    .ng-option {
      white-space: normal;

      &.ng-option-marked {
        background-color: theme-lightness($--tl-theme-accent10, 3%);
      }

      &.ng-option-selected,
      &.ng-option-selected.ng-option-marked {
        background-color: theme-var($--tl-theme-accent10, 1%);

        .ng-option-label {
          font-weight: normal;
          display: block;
        }
      }
    }
  }

  .ng-dropdown-footer {
    padding-left: 0;
    padding-right: 0;

    .btn {
      padding-left: 7px;
      padding-right: 7px;
      text-align: left;
      width: 100%;

      &:hover {
        background-color: theme-lightness($--tl-theme-accent10, 3%);
      }
    }
  }
}


//policy-redaction-selector component
.policy-redaction-selector.ng-select {
  .ng-select-container {
    min-height: 24px;
  }

  &.ng-select-single .ng-select-container {
    height: 24px;

    .ng-value-container {
      height: 24px;

      .ng-input {
        top: 0;
      }
    }
  }
}
