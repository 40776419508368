@import 'variables';

.risk-score-icon.RISK {
  color: theme-var($--tl-theme-warn);
}

.risk-score-icon.VALIDATION {
  color: theme-var($--tl-theme-primary);
}

.risk-score-icon.INFORMATION {
  color: theme-var($--tl-theme-info);
}

.risk-score-icon.MULTIPLE {
  display: inline-block;
  width: 15px;
  margin: 0;
  text-align: center;
  border-radius: 7px;
  background: theme-var($--tl-theme-quaternary);
  color: theme-var($--tl-theme-dark-text);
  font: theme-var($--tl-theme-font-caption);
  height: 15px;
  line-height: 1.5;
  vertical-align: baseline;
  top: -1px;
  position: relative;
}
